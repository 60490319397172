import { useEffect, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil";

import { doUserLogout, removeSessionLocalStorage } from "../../services";
import { tokenState, currentUserProfileState } from '../../store';

const Logout = () => {

    const [token, setToken] = useRecoilState(tokenState);
    const setUserData = useSetRecoilState(currentUserProfileState);

    // Set local storage state
    removeSessionLocalStorage();

    // Send feedback to backend
    const setLogout = useCallback(async () => {
        const logoutResponse = await doUserLogout(token);
        return logoutResponse;
    }, [token]);

    useEffect(() => {

        // Set token to null
        setToken(null);

        // Set user data to null
        setUserData(null);

        setLogout();

    }, [setLogout, setToken, setUserData]);

    return null;

};

export default Logout;