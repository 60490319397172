import React from 'react';

import zajedno from "../../assets/images/zajendo-eu.png";
import eustrukturni from "../../assets/images/eu-strukturni.jpg";
import nacionalna from "../../assets/images/nacionalna.jpg";
import synergia from "../../assets/images/synergia.png";
import uljp from "../../assets/images/uljp.png";

const Footer = () => {

    return (
        <div className={`d-flex flex-wrap justify-content-md-between py-2 px-2 align-items-center`}>
            <div className='w-100 text-center'>
                Sadržaj aplikacije isključiva je odgovornost tvrtke Synergia savjetovanje d.o.o.
                Projekt je sufinancirala Europska unija iz Europskog socijalnog fonda.
            </div>
            <div className='w-100 d-flex flex-wrap flex-center py-3 justify-content-center align-items-center'>
                <div>
                    <img src={zajedno} alt="zajedno" className='w-100' />
                </div>
                <div>
                    <img src={eustrukturni} alt="strukturni" className='w-100' />
                </div>
                <div>
                    <img src={nacionalna} alt="nacionalna" className='w-100' />
                </div>
                <div>
                    <img src={synergia} alt="synergia" className='w-100' />
                </div>
                <div>
                    <img src={uljp} alt="uljp" className='w-100' />
                </div>
            </div>
        </div>

    );

};

export default Footer;