import { atom, selector } from "recoil";
import { getSessionLocalStorage } from "../services";

export const currentUserProfileState = atom({
    key: 'currentUserProfile',
    default: getSessionLocalStorage() ? getSessionLocalStorage().data : null,
});

export const currentUserPermissionsState = selector({
    key: 'currentUserPermissions',
    get: async ({ get }) => {
        let permissionsArray = [];
        const userData = get(currentUserProfileState);
        
        if (userData != null){
            permissionsArray = Object.keys(userData.permissions).map((key) => {
                return userData.permissions[key].permission_path;
            });
        }

        return permissionsArray;

    },
});