import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBottom from "../../../components/Layout/HeaderBottom";

const UsersHeader = ({ children, page }) => {

    return (
        <HeaderBottom>
            <div className="headerLeft">
                <h2 className="d-inline-block">
                    <Link to={`/manage-users/all/`}>Korisnici</Link>
                </h2>
                {children}
            </div>
            <div className="header-right">
                <Link to={`/manage-users/new/${page}`} className="btn btn-light-blue">Dodaj novog korisnika</Link>
            </div>
        </HeaderBottom>
    );

};

export default UsersHeader;