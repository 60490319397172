import React from 'react';
import { Link } from "react-router-dom";

import classes from './PaginationList.module.css';

const PaginationList = (props) => {

    const midSize = props.midSize ? props.midSize : 1;
    const endSize = props.endSize ? props.endSize : 2;

    let dots = false;
    let items = [];

    // Previous page and First page
    if (props.numberOfPages > 1 && props.currentPage > 1) {
        items.push(<li className="page-item" key="first"><Link to={`${props.linkHref}/1`} className="page-link"><span aria-hidden="true">«</span></Link></li>);
        const previousPage = props.currentPage - 1;
        items.push(<li className="page-item" key="previous"><Link to={`${props.linkHref}/${previousPage}`} className="page-link"><span aria-hidden="true">‹</span><span className="visually-hidden">Previous</span></Link></li>);
    }

    // Pages in middle
    for (let i = 1; i <= props.numberOfPages; i++) {

        // Current page
        if (i === props.currentPage) {
            items.push(
                <li className="page-item active" key={i}>
                    <span className="page-link">
                        {i}<span className="visually-hidden">(current)</span>
                    </span>
                </li>
            );
            dots = true;
        }
        // Other pages
        else {
            // Show page link
            if (
                i <= endSize ||
                (
                    props.currentPage && i >= props.currentPage - midSize &&
                    i <= props.currentPage + midSize
                ) ||
                i > props.numberOfPages - endSize
            ) {
                items.push(<li className="page-item" key={i}><Link to={`${props.linkHref}/${i}`} className="page-link">{i}</Link></li>);
                dots = true;
            }
            // Show dots
            else if (dots) {
                items.push(<li className="page-item" key="dots"><span className="page-link" tabIndex="0"><span aria-hidden="true">…</span><span className="visually-hidden">More</span></span></li>);
                dots = false;
            }

        }

    }

    // Next page and Last page
    if (props.currentPage < props.numberOfPages) {
        const nextPage = props.currentPage + 1;
        items.push(<li className="page-item" key="next"><Link to={`${props.linkHref}/${nextPage}`} className="page-link"><span aria-hidden="true">›</span><span className="visually-hidden">Next</span></Link></li>);
        items.push(<li className="page-item" key="last"><Link to={`${props.linkHref}/${props.numberOfPages}`} className="page-link"><span aria-hidden="true">»</span><span className="visually-hidden">Last</span></Link></li>);
    }

    return (
        <div className={`d-flex justify-content-between ${classes.paginationList}`}>
            {props.total && <div className="pagination-left">Ukupno {props.total} rezultata</div>}
            <ul className="pagination">
                {items}
            </ul>
        </div>
    );
};

export default PaginationList;