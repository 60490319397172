import Recoil, { atom, selectorFamily } from "recoil";
import { tokenState } from ".";
import { 
    getProjectsData, 
    getOneProjectData, 
    getProjectCategoriesData, 
    getProjectYearsData,
    getProjectYearTotalsData,
    getProjectCategoriesIncomeOrExpanse
} from "../services";

export const manageProjectsTriggerState = atom({
    key: 'manageProjectsTrigger',
    default: 0
});

export const projectsState = selectorFamily({
    key: 'projects',
    get: (id) => async ({ get }) => {
        get(manageProjectsTriggerState);
        const response = await getProjectsData(get(tokenState), id);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(manageProjectsTriggerState, v => v + 1);
        }
    }
});

export const projectEditState = selectorFamily({
    key: 'projectEdit',
    get: ({ id, clientID }) => async ({ get }) => {
        const response = await getOneProjectData(get(tokenState), id, clientID);

        return response.data;
    }
});

export const manageProjectCategoriesTriggerState = atom({
    key: 'manageProjectCategoriesTrigger',
    default: 0
});


export const projectCategoriesState = atom({
    key: 'projectCategories',
    default: []
});

export const projectCategoriesFetchState = selectorFamily({
    key: 'projectCategoriesFetch',
    get: ({ projectID, clientID }) => async ({ get }) => {

        const response = await getProjectCategoriesData(get(tokenState), projectID, clientID);

        return response.data;
    },
    set: () => ({ set, get }, value) => {

        const projectCategories = get(projectCategoriesState);

        let newProjectCategories = [];

        if (value.type === 'ADD') {
            newProjectCategories = addNewProjectCategoriesState(projectCategories, value.item);
        }
        else if (value.type === 'EDIT') {
            newProjectCategories = editProjectCategoriesState(projectCategories, value.item);
        }
        else if (value.type === 'DELETE') {
            newProjectCategories = deleteProjectCategoriesState(projectCategories, value.item);
        }

        set(projectCategoriesState, newProjectCategories);
    }
});

/**
 * Add new category to project categories state
 */
const addNewProjectCategoriesState = (projectCategories, item) => {

    if (item.parent_id === null) {
        return [...projectCategories, item];
    }
    else {
        return [...projectCategories].map((data, i) => {
            let newData = { ...data };

            if (data.id === parseInt(item.parent_id)) {
                newData.childs = (newData.childs)? newData.childs : [];
                newData.childs = [ ...newData.childs, item ];
            }
            else {
                let newProjectCategories1 = newData.childs.map((data1, i1) => {
                    let newData1 = { ...data1 };
                    if (data1.id === parseInt(item.parent_id)) {
                        newData1.childs = (newData1.childs)? newData1.childs : [];
                        newData1.childs = [ ...newData1.childs, item ];
                    }
                    return newData1;
                });

                newData.childs = newProjectCategories1;
            }

            return newData;
        });
    }

}

/**
 * Edit category in project categories state
 */
const editProjectCategoriesState = (projectCategories, item) => {

    return [...projectCategories].map((data, i) => {
        let newData = { ...data };
        if (data.id === item.id) {
            newData = { ...data, ...item };
        }
        // Second depth
        else if (newData.childs && newData.childs.length > 0) {
            let newProjectCategories1 = newData.childs.map((data1, i1) => {
                let newData1 = { ...data1 };
                if (data1.id === item.id) {
                    newData1 = { ...data1, ...item };
                }
                // Third depth
                else if (newData1.childs && newData1.childs.length > 0) {
                    let newProjectCategories2 = newData1.childs.map((data2, i2) => {
                        let newData2 = { ...data2 };
                        if (data2.id === item.id) {
                            newData2 = { ...data2, ...item };
                        }

                        return newData2;
                    });

                    newData1.childs = newProjectCategories2;
                }

                return newData1;
            });

            newData.childs = newProjectCategories1;
        }
        return newData;
    });

}

/**
 * Delete category in project categories state
 */
const deleteProjectCategoriesState = (projectCategories, item) => {

    let newProjectCategories = [...projectCategories];

    if (item.parent_id === null) {
        return newProjectCategories.filter(element => element.id !== item.id);
    }
    else {
        return [...projectCategories].map((data, i) => {
            let newData = { ...data };

            if (data.id === parseInt(item.parent_id)) {
                newData.childs = newData.childs.filter(element => element.id !== item.id);
            }
            else {
                let newProjectCategories1 = newData.childs.map((data1, i1) => {
                    let newData1 = { ...data1 };
                    if (data1.id === parseInt(item.parent_id)) {
                        newData1.childs = newData1.childs.filter(element => element.id !== item.id);
                    }
                    return newData1;
                });

                newData.childs = newProjectCategories1;
            }

            return newData;
        });
    }

}

/**
 * Project current year for viewing income/expanse table
 */
export const projectCurrentYearState = atom({
    key: 'projectCurrentYear',
    default: new Date().getFullYear()
});

export const projectColumnsState = atom({
    key: 'projectColumns',
    default: {
        1: {'column': 1, 'title': 'Godina', 'month': null, total: 0.0},
        2: {'column': 2, 'title': 'siječanj', 'month': 1, total: 0.0},
        3: {'column': 3, 'title': 'veljača', 'month': 2, total: 0.0},
        4: {'column': 4, 'title': 'ožujak', 'month': 3, total: 0.0},
        5: {'column': 5, 'title': 'Q1', 'month': null, total: 0.0},
        6: {'column': 6, 'title': 'travanj', 'month': 4, total: 0.0},
        7: {'column': 7, 'title': 'svibanj', 'month': 5, total: 0.0},
        8: {'column': 8, 'title': 'lipanj', 'month': 6, total: 0.0},
        9: {'column': 9, 'title': 'Q2', 'month': null, total: 0.0},
        10: {'column': 10, 'title': 'srpanj', 'month': 7, total: 0.0},
        11: {'column': 11, 'title': 'kolovoz', 'month': 8, total: 0.0},
        12: {'column': 12, 'title': 'rujan', 'month': 9, total: 0.0},
        13: {'column': 13, 'title': 'Q3', 'month': null, total: 0.0},
        14: {'column': 14, 'title': 'listopad', 'month': 10, total: 0.0},
        15: {'column': 15, 'title': 'studeni', 'month': 11, total: 0.0},
        16: {'column': 16, 'title': 'prosinac', 'month': 12, total: 0.0},
        17: {'column': 17, 'title': 'Q4', 'month': null, total: 0.0},
        18: {'column': 18, 'title': 'TOTAL', 'month': null, total: 0.0},
    }
});

export const projectYearsState = selectorFamily({
    key: 'projectYears',
    get: ({ projectID, clientID }) => async ({ get }) => {

        const response = await getProjectYearsData(get(tokenState), projectID, clientID);

        return response.data;
    }
});

export const projectYearTotalsTriggerState = atom({
    key: 'projectYearTotalsTrigger',
    default: 0
});

export const projectYearTotalsState = selectorFamily({
    key: 'projectYearTotals',
    get: ({ projectID, clientID, currentYear }) => async ({ get }) => {
        get(projectYearTotalsTriggerState);
        const response = await getProjectYearTotalsData(get(tokenState), projectID, clientID, currentYear);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(projectYearTotalsTriggerState, v => v + 1);
        }
    }
});

export const projectCategoriesIncomeOrExpanseState = selectorFamily({
    key: 'projectCategoriesIncomeOrExpanse',
    get: ({ selectedProjectID, clientID, incomeExpanseMark }) => async ({ get }) => {
        
        if(incomeExpanseMark == null){
            return [];
        }

        const response = await getProjectCategoriesIncomeOrExpanse(get(tokenState), selectedProjectID, clientID, incomeExpanseMark);

        return response.data;
    }
});