import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { tokenState, successState, bankStatementsState } from "../../store";
import { postNewBankStatementData } from "../../services";

import BankStatementHeader from './BankStatementHeader';
import FormElement from "../../components/elements/FormElement";

import InputFile from '../../components/UI/InputFile';
import Button from '../../components/UI/Button';

export const AddNewBankStatement = () => {

    const { clientID } = useParams();
    const page = 1;

    const token = useRecoilValue(tokenState);
    const itemsReset = useResetRecoilState(bankStatementsState({clientID, page}));
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const fileInputRef = useRef();
    
    const [errorBankFile, setErrorBankFile] = useState(false);
    let errorBankFileMessage = 'Odabirete XML datoteku';

    const checkFileType = (event) => {
        const enteredFile = fileInputRef.current.files[0];
        if(enteredFile.type === "text/xml"){
            setErrorBankFile(false);
            globalError = false;
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredFile = fileInputRef.current.files[0];

        // Validate data
        if (!enteredFile) {
            setErrorBankFile(true);
            globalError = true;
        }
        else if(enteredFile.type !== "text/xml"){
            setErrorBankFile(true);
            globalError = true;
        }
        else {
            setErrorBankFile(false);
        }

        // Add new bank statement if everything ok
        if (
            !globalError
        ) {

            let formData = new FormData();

            formData.append('file', fileInputRef.current.files[0]);

            const response = await postNewBankStatementData(formData, token, clientID, true);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                itemsReset(v => v + 1);

                setSuccess(response.message);

                navigate('/bank-statements/all/' + clientID);
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <BankStatementHeader>
                <span className="d-inline-block ps-1">• Novi bankovni izvadak</span>
            </BankStatementHeader>
            <div className="d-flex flex-column align-items-center justify-content-center" onChange={checkFileType}>
                <FormElement onSubmitHandler={handleFormSubmit} title="Dodaj novi bankovni izvadak" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <InputFile
                        ref={fileInputRef}
                        name="bank-file"
                        label="Datoteka"
                        autoComplete="off"
                        fileTypeCheck="text/xml"
                        fileTypeCheckMessage="Niste dodali XML datoteku"
                        error={errorBankFile ? errorBankFileMessage : null}
                    />
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Cancel</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Dodaj novi bankovni izvadak</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};