import { fetchWrapperClass } from './fetchWrapper';
import { bankStatementUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getBankStatementsData = async (clientID, token, page) => {
    return await fetchWrapperUser.get(bankStatementUrl + '/' + clientID + '/bank-statements?page=' + page, token).then(data => { return data; });
};

export const postNewBankStatementData = async (postData, token, clientID, isFile) => {
    return await fetchWrapperUser.post(bankStatementUrl + '/' + clientID + '/bank-statements', token, postData, isFile).then(data => { return data; });
};

export const getOneBankStatementData = async (clientID, token, id) => {
    return await fetchWrapperUser.get(bankStatementUrl + '/' + clientID + '/bank-statements/' + id, token).then(data => { return data; });
};

export const deleteBankStatementData = async (clientID, id, token) => {
    return await fetchWrapperUser.delete(bankStatementUrl + '/' + clientID + '/bank-statements/' + id, token).then(data => { return data; });
};

export const getBankStatementsEntriesData = async (clientID, token, id) => {
    return await fetchWrapperUser.get(bankStatementUrl + '/' + clientID + '/bank-statements/' + id + '/entries', token).then(data => { return data; });
};

export const getBankStatementsEntryData = async (clientID, token, id) => {
    return await fetchWrapperUser.get(bankStatementUrl + '/' + clientID + '/bank-statements/' + id + '/entry', token).then(data => { return data; });
};

export const postAddBankStatementInputExpanseData = async (postData, bankStatementID, entryID, clientID, token) => {
    return await fetchWrapperUser.post(bankStatementUrl + '/' + clientID + '/bank-statements/' + bankStatementID + '/addEntryToIncomeExpanse/' + entryID, token, postData).then(data => { return data; });
};