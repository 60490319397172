import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilValue } from "recoil";

import { clientState } from "../../store";

import HeaderBottom from "../../components/Layout/HeaderBottom";

const ProjectsHeader = ({ children, page }) => {

    const { clientID } = useParams();

    const clientData = useRecoilValue(clientState(clientID));

    return (
        <HeaderBottom>
            <div className="headerLeft">
                <h2 className="d-inline-block me-1">
                    <Link to={`/`}>Upravljačka ploča</Link>
                </h2> • <Link to={`/client-projects/${clientData.slug}`} className="ms-1">{clientData.name}</Link>
                {children}
            </div>
            <div className="headerRight">
                <Link to={`/projects/new/${clientID}`} className="btn btn-light-blue">Dodaj novi projekt</Link>
            </div>
        </HeaderBottom>
    );
};

export default ProjectsHeader;