const apiDomain = "https://iemadmin.somevps3.com/api/";

export const loginUrl = apiDomain + 'users/login';
export const logoutUrl = apiDomain + 'users/logout ';
export const resetPaswordUrl = apiDomain + 'users/forgot-password';
export const resetPasswordTokenCheckUrl = apiDomain + 'users/reset-password-token-check';
export const resetPasswordNewUrl = apiDomain + 'users/reset-password-new';

export const userProfileUrl = apiDomain + 'view-profile';
export const userUrl = apiDomain + 'manage-users';

export const clientUrl = apiDomain + 'clients';

export const dashboardUrl = apiDomain + 'dashboard';

export const projectUrl = apiDomain + 'clients';

export const basicCategoriesUrl = apiDomain + 'basic-categories';

export const projectDataUrl = apiDomain + 'project';

export const categoriesUrl = apiDomain + 'categories';

export const incomeExpanseUrl = apiDomain + 'income-expanse';

export const bankStatementUrl = apiDomain + 'clients';