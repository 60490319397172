import React, { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { tokenState, successState } from "../../../store";
import { postEditCategoryData } from "../../../services";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../../elements/FormElement';
import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button';

import classes from './CategoryModal.module.css';

const CategoryEditModal = (props) => {

    const id = props.item ? props.item.id : null
    const projectID = props.projectID;
    const clientID = props.clientID;

    const itemEditData = props.item;
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const orderingInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv kategorije.';

    const [errorOrdering, setErrorOrdering] = useState(false);
    const errorOrderingMessage = 'Upišite ispravni redoslijed kategorije.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredOrdering = orderingInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }
        if (!validateEmptyInput(enteredOrdering)) {
            setErrorOrdering(true);
            globalError = true;
        }
        else {
            setErrorOrdering(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postEditCategoryData({ enteredName, enteredOrdering }, id, projectID, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);
                props.updateValue({item: response.item, type: 'EDIT'});
                props.hide();
            }

        }

    }

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>
                <Modal.Title>Kategorija <em>{props.item ? props.item.name : null}</em></Modal.Title>
            </Modal.Header>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <Modal.Body>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Kategorija"
                        autoComplete="off"
                        defaultValue={itemEditData ? itemEditData.name : null}
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={orderingInputRef}
                        name="ordering"
                        type="number"
                        label="Redoslijed"
                        autoComplete="off"
                        defaultValue={itemEditData ? itemEditData.ordering : null}
                        error={errorOrdering ? errorOrderingMessage : null}
                        minLength="1"
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Zatvori</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Sačuvaj izmjene</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default CategoryEditModal;