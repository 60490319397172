import React from 'react';
import { useRecoilValue } from 'recoil';

import { projectCurrentYearState, projectYearTotalsState, projectColumnsState, projectCategoriesState } from '../../../store';

import { tablePrice } from '../../../helpers/project';

const ProjectTableExpanse = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const projectCategories = useRecoilValue(projectCategoriesState);
    const columns = useRecoilValue(projectColumnsState);
    const currentYear = useRecoilValue(projectCurrentYearState);
    const yearTotals = useRecoilValue(projectYearTotalsState({ projectID, clientID, currentYear }));

    const getCategoryTotalSum = (currentCategory, month) => {
        let categorySum = 0;

        yearTotals.map(total => {
            if (parseInt(total.month_number) === month && total.project_category_id === currentCategory.id) {
                categorySum += parseFloat(total.expanse);
            }
            return null;
        });

        // Check child values
        if (currentCategory.childs && currentCategory.childs.length > 0) {
            currentCategory.childs.map(option2 => {
                categorySum += getCategoryTotalSum(option2, month);
                return null;
            });
        }

        return categorySum;
    };

    let totalMonth = [
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
        0.0
    ];

    const returnOptionRow = (option, i, childPrefix) => {

        let totalQ = 0.0;
        let totalY = 0.0;

        return (
            <React.Fragment key={i}>
                <tr className={`expenseRow ${childPrefix}`}>
                    <td className={`ids-${option.id}`}>{option.name}</td>
                    {Object.keys(columns).map((key, j) => {
                        if (j === 0) { return null; }

                        let tdClass = null;

                        let categorySum = getCategoryTotalSum(option, columns[key].month);

                        totalQ += categorySum;
                        totalY += categorySum;

                        // Show quartall prices
                        if (j === 4 || j === 8 || j === 12 || j === 16) {
                            tdClass = 'quartal';
                            categorySum = totalQ;
                            totalQ = 0.0;
                        }
                        if (j === 17) {
                            tdClass = 'total';
                            categorySum = totalY;
                            totalQ = 0.0;
                            totalY = 0.0;
                        }

                        const keyMonth = j - 1;

                        // Add only main category to summary
                        if(option.parent_id === null){
                            totalMonth[keyMonth] += categorySum;
                        }

                        return <td key={j} className={tdClass}>{tablePrice(categorySum)}</td>
                    })}
                </tr>
                {option.childs && option.childs.map((option2, z) => {
                    childPrefix += 'padRow';
                    return returnOptionRow(option2, z, childPrefix);
                })}
            </React.Fragment>
        );

    }

    return (
        <React.Fragment>
            {projectCategories && projectCategories.map((option, i) => {

                if (option.income_expanse !== 'E') { return null; } // Show only EXPENSE DATA

                const rowHtml = returnOptionRow(option, i, '');

                return (
                    <React.Fragment key={i}>
                        {rowHtml}
                    </React.Fragment>
                );
            })}
            <tr key="total" className="totalRow">
                <td key="totalName">UKUPNO ODLJEVI:</td>
                {totalMonth && totalMonth.map((option, i) => {

                    let tdClass = null;

                    if (i === 3 || i === 7 || i === 11 || i === 15) {
                        tdClass = 'quartal';
                    }

                    return (<td key={i} className={tdClass}>{tablePrice(option)}</td>);
                })}
            </tr>
        </React.Fragment>
    );
}

export default ProjectTableExpanse;