import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './ForgotPasswordForm.module.css'

import Input from '../UI/Input';
import Button from '../UI/Button';

import { validateEmail } from '../../helpers/inputValidate';
import { doUserResetPassword } from '../../services';

const ForgotPasswordForm = () => {

    /* State variables */
    let navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState(null);

    /* Form login part with validation */
    let globalError = false;
    const emailInputRef = useRef();

    const [errorEmail, setErrorEmail] = useState(false);
    const errorEmailMessage = 'Upišite ispravnu email adresu.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredEmail = emailInputRef.current.value;

        // Validate email and password
        if (!validateEmail(enteredEmail)) {
            setErrorEmail(true);
            globalError = true;
        }
        else {
            setErrorEmail(false);
        }

        // Log user if everything ok
        if (!globalError) {

            const response = await doUserResetPassword(enteredEmail);

            if (response) {
                setResponseMessage(response.message);
            }

        }

    }

    return (
        <div className={`d-flex flex-grow-1 h-100 justify-content-center ${classes.login_right}`}>
            <div className="d-flex flex-column justify-content-center">
                <form className="form" onSubmit={handleFormSubmit}>
                    <div className={`${classes.form_header} form-header mb-5 text-center`}>
                        <h2 className="mb-3">Zaboravio si lozinku?</h2>
                        <div>
                            Nemate pristup? <a href="mailto:poduzetnicki.centar@synergia-consulting.hr" className="link-blue">Kontaktirajte korisničku podršku</a>
                        </div>
                    </div>
                    {!responseMessage && (
                        <>
                            <Input
                                ref={emailInputRef}
                                name="email"
                                type="email"
                                label="Tvoj email za resetiranje lozinke"
                                autoComplete="off"
                                error={errorEmail ? errorEmailMessage : null} />
                            <div className="text-center">
                                <Button
                                    name="submit"
                                    type="submit"
                                >
                                    Pošalji link za resetiranje lozinke
                                </Button>
                            </div></>
                    )}
                    {responseMessage &&
                        <>
                            <div className={classes.responseDiv}>{responseMessage}</div>
                            <div className='text-center'>
                                <Button
                                    onClick={() => navigate('/')}
                                >
                                    Povratak na prijavu
                                </Button>
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;