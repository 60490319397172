import React from 'react';
import classes from './Button.module.css'

const Button = (props) => {

    const buttonClasses = props.styles ? props.styles + ' ' + classes.button_blue : classes.button_blue;

    return (
        <button
            id={props.name}
            type={props.type}
            onClick={props.onClick}
            className={buttonClasses}
        >
            {props.children}
        </button>
    );
};

export default Button;