import React from 'react';
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Logo = ({styles}) => {
    return (
        <div className={`logo ${styles}`}>
            <Link to="/">
                <img src={logo} alt="logo" />
            </Link>
        </div>
    );
};

export default Logo;