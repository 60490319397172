import { fetchWrapperClass } from './fetchWrapper';
import { basicCategoriesUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getBasicCategoriesData = async (token) => {
    return await fetchWrapperUser.get(basicCategoriesUrl, token).then(data => { return data; });
};

export const postNewBasicCategoryData = async (postData, token) => {
    return await fetchWrapperUser.post(basicCategoriesUrl, token, postData).then(data => { return data; });
};

export const getOneBasicCategoryData = async (token, id) => {
    return await fetchWrapperUser.get(basicCategoriesUrl + '/' + id, token).then(data => { return data; });
};

export const postEditBasicCategoryData = async (postData, id, token) => {
    return await fetchWrapperUser.put(basicCategoriesUrl + '/' + id, token, postData).then(data => { return data; });
};

export const deleteBasicCategoryData = async (id, token) => {
    return await fetchWrapperUser.delete(basicCategoriesUrl + '/' + id, token).then(data => { return data; });
};