import React, { useState } from 'react';
import classes from './Input.module.css'
import Button from './Button';

const InputFile = React.forwardRef((props, ref) => {

    const [error, setError] = useState(props.error);
    const [fileData, setFileData] = useState();

    const inputClasses = props.styles ? props.styles + classes.input_over : classes.input_over;

    const handleButtonClick = () => {
        ref.current.click();
    };

    const handleFileInputChange = (event) => {

        if(event.target.files){
            setFileData(event.target.files[0].name);
        }

        if (props.fileTypeCheck && event.target.files.length > 0 && event.target.files[0].type !== props.fileTypeCheck) {
            setError(props.fileTypeCheckMessage);
        }
        else{
            
            if (props.fileTypeCheck && event.target.files.length > 0 && event.target.files[0].type === props.fileTypeCheck) {
                setError(null);
            }
            else{
                setError(props.error);
            }

        }
    };

    return (
        <div className={inputClasses}>
            <label htmlFor={props.name} className={`form-label ${classes.form_label}`}>
                {props.label}
                {props.additionalLabel &&
                    <div className={classes.additionalLabel}>{props.additionalLabel}</div>
                }
            </label>
            <Button onClick={handleButtonClick} type="button">
                Izaberite datoteku
            </Button>
            {fileData && <div className="mt-2">Datoteka: {fileData}</div>}
            <input
                ref={ref}
                type="file"
                id={props.name}
                name={props.name}
                aria-describedby={`${props.name}Help`}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
            />
            {props.help &&
                <div id={`${props.name}Help`} className="form-text">{props.help}</div>
            }
            {error &&
                <div id={`${props.name}Error`} className={`form-text ${classes.form_error_text}`}>{error}</div>
            }
            {props.error && !error &&
                <div id={`${props.name}Error`} className={`form-text ${classes.form_error_text}`}>{props.error}</div>
            }
        </div>
    );
});

export default InputFile;