import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { useNavigate, Link } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import Table from 'react-bootstrap/Table';

import PaginationList from "../../../components/elements/PaginationList";

import { currentUserPermissionsState, manageUsersState } from "../../../store";

import UsersHeader from './UsersHeader';
import ModalConfirm from "../../../components/UI/ModalConfirm";

export const Users = () => {

    const { page } = useParams()
    const currentPage = (page) ? parseInt(page) : 1;
    const items = useRecoilValue(manageUsersState(currentPage));
    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [modalNavigate, setModalNavigate] = useState('/manage-users/all/1');

    // Check user permission
    useEffect(() => {

        if (!userPermissions.includes('manage_users')) {
            navigate('/');
        }

    }, [userPermissions, navigate]);

    const handleModalWindow = (title, text, navigate) => {
        setShowModal(true);
        setModalTitle(title);
        setModalText(text);
        setModalNavigate(navigate);
    }

    const handleModalHide = () => {
        setShowModal(false);
    }

    return (
        <div className="flex-grow-1">
            <UsersHeader page={currentPage} />
            <div className="content">
                <Table className="table-list">
                    <thead>
                        <tr>
                            <th scope="col">Ime i prezime</th>
                            <th scope="col">Email</th>
                            <th scope="col">Klijent</th>
                            <th scope="col">Opcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.data &&
                            items.data.map((item) => {
                                return (
                                    <tr key={item.slug}>
                                        <td><Link to={`/manage-users/edit/${item.slug}/${currentPage}`}>{item.name}</Link></td>
                                        <td>{item.email}</td>
                                        <td>{item.client.name}</td>
                                        <td>
                                            <Link to={`/manage-users/edit/${item.slug}/${currentPage}`} className="btn btn-blue-nbg">Izmjeni</Link>
                                            <button
                                                onClick={() => {
                                                    handleModalWindow('Da li ste sigurni da želite izbrisati korisnika?', item.name, '/manage-users/delete/' + item.slug + '/' + currentPage);
                                                    }
                                                }
                                                className="ms-3 btn btn-red-nbg"
                                            >Izbriši</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                <PaginationList
                    linkHref="/manage-users/all"
                    currentPage={currentPage}
                    perPage={items.perPage}
                    numberOfPages={items.last_page}
                    total={items.total}
                />
            </div>
            <ModalConfirm show={showModal} title={modalTitle} text={modalText} hide={handleModalHide} navigate={modalNavigate} />
        </div>
    );
};