import Recoil, { atom, selectorFamily } from "recoil";
import { tokenState } from ".";
import { 
    getIncomeExpansesData,
    getOneIncomeExpanseData
} from "../services";

export const incomeExpansesTriggerState = atom({
    key: 'incomeExpansesTrigger',
    default: 0
});

export const incomeExpansesState = selectorFamily({
    key: 'incomeExpanses',
    get: ({ clientID, projectID, currentPage }) => async ({ get }) => {
        get(incomeExpansesTriggerState);
        const response = await getIncomeExpansesData(clientID, projectID, get(tokenState), currentPage);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(incomeExpansesTriggerState, v => v + 1);
        }
    }
});

export const incomeExpanseEditState = selectorFamily({
    key: 'incomeExpanseEdit',
    get: ({clientID, projectID, incomeExpanseID}) => async ({ get }) => {
        const response = await getOneIncomeExpanseData(clientID, projectID, get(tokenState), incomeExpanseID);

        return response.data;
    }
});
