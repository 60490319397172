import { useEffect, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { currentUserPermissionsState, tokenState, successState, clientsEditState, clientsState } from "../../store";
import { deleteClientData } from "../../services";

export const DeleteProject = () => {

    const {id, page} = useParams()

    const itemData = useRecoilValue(clientsEditState(id));
    const itemsReset = useResetRecoilState(clientsState(parseInt(page)));

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    const deleteItem = useCallback(async (id) => {

        const response = await deleteClientData(id, token);
    
            if( response.success ){
    
                itemsReset(v => v +1);
    
                setSuccess(response.message);

                navigate('/clients/all/1');
            }
            else{
                navigate('/clients/all/1');
            }
    }, [navigate, setSuccess, token, itemsReset]);

    // Check user permission and delete it
    useEffect(() => {

        if( id && itemData ){
            deleteItem(itemData.slug);
        }

        if (!userPermissions.includes('manage_clients')) { navigate('/'); }

    }, [userPermissions, navigate, id, itemData, deleteItem]);

    return null;

};