import { atom } from "recoil";

export const successState = atom({
    key: 'success',
    default: null
});

export const errorState = atom({
    key: 'error',
    default: null
});