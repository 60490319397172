import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { useRecoilValue } from "recoil";

import { projectsState, clientState } from "../store";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderBottom from "../components/Layout/HeaderBottom";
import CardBox from "../components/UI/CardBox";

const ClientProjects = () => {

    const { id: clientID } = useParams();

    const clientData = useRecoilValue(clientState(clientID));
    const items = useRecoilValue(projectsState(clientID));

    return (
        <div className="flex-grow-1">
            <HeaderBottom>
                <div className="headerLeft">
                    <h2 className="d-inline-block">
                        <Link to={`/`}>Upravljačka ploča</Link>
                    </h2>
                    <span className="d-inline-block ps-1">• <em>{clientData.name}</em> • Projekti</span>
                </div>
                <div className="headerRight">
                    <Link to={`/projects/new/${clientID}`} className="btn btn-light-blue">Dodaj novi projekt</Link>
                </div>
            </HeaderBottom>
            <div className="content">
                <Row xs={1} md={3} className="g-4">
                    {items.map((option, i) => {

                        const oneDay = 24 * 60 * 60 * 1000;
                        const firstDate = new Date(option.end_date);
                        const secondDate = new Date();
                        
                        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

                        const titleData = (
                            <div className="row justify-content-between">
                                <Link to={`/project/${option.slug}/${clientID}`} className="col-auto">{option.name}</Link>
                                <Link to={`/projects/edit/${option.slug}/${clientID}`} className="btn-edit col-auto">Uredi projekt</Link> 
                            </div>
                        );

                        const footerData = (
                            <div className="d-flex justify-content-between">
                                <Link to={`/project/${option.slug}/${clientID}`}>Pregledaj projekt</Link>
                                <Link to={`/projects/edit/${option.slug}/${clientID}`}>Uredi projekt</Link> 
                                {option.end_date && <span>{diffDays} dana do kraja</span>}
                            </div>
                        );

                        return <Col key={i}><CardBox title={titleData} footer={footerData} /></Col>
                    })}
                </Row>
            </div>
        </div>
    );
};

export default ClientProjects;