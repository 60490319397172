import Recoil, { atom, selectorFamily } from "recoil";
import { tokenState } from ".";
import {
    getBankStatementsData,
    getOneBankStatementData,
    getBankStatementsEntriesData,
    getBankStatementsEntryData
} from "../services";

export const bankStatementTriggerState = atom({
    key: 'bankStatementTrigger',
    default: 0
});

export const bankStatementsState = selectorFamily({
    key: 'bankStatements',
    get: ({ clientID, currentPage }) => async ({ get }) => {
        get(bankStatementTriggerState);
        const response = await getBankStatementsData(clientID, get(tokenState), currentPage);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(bankStatementTriggerState, v => v + 1);
        }
    }
});

export const bankStatementEditState = selectorFamily({
    key: 'bankStatementEdit',
    get: ({clientID, id}) => async ({ get }) => {
        const response = await getOneBankStatementData(clientID, get(tokenState), id);

        return response.data;
    }
});

export const bankStatementEntriesTriggerState = atom({
    key: 'bankStatementEntriesTrigger',
    default: 0
});


export const bankStatementsEntriesState = selectorFamily({
    key: 'bankStatementEntriesTrigger',
    get: ({ clientID, id }) => async ({ get }) => {
        get(bankStatementEntriesTriggerState);
        const response = await getBankStatementsEntriesData(clientID, get(tokenState), id);

        return response.items;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(bankStatementEntriesTriggerState, v => v + 1);
        }
    }
});

export const bankStatementsEntryState = selectorFamily({
    key: 'bankStatementsEntry',
    get: ({clientID, entryID}) => async ({ get }) => {
        const response = await getBankStatementsEntryData(clientID, get(tokenState), entryID);

        return response.item;
    }
});