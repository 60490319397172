import React from 'react';

const MainLayout = ({children}) => {
    return (
        <div className="d-flex main-layout h-100">
            {children}
        </div>
    );
};

export default MainLayout;