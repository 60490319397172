import React from 'react';
import classes from './IncomeExpansesPagination.module.css';

const IncomeExpansesPagination = (props) => {
    
    const midSize = props.midSize ? props.midSize : 1;
    const endSize = props.endSize ? props.endSize : 2;

    let dots = false;
    let items = [];

    // Previous page and First page
    if (props.numberOfPages > 1 && props.currentPage > 1) {
        items.push(<li className="page-item" key="first"><a href="#!" onClick={() => props.goToPage(1)} className="page-link"><span>«</span></a></li>);
        const previousPage = props.currentPage - 1;
        items.push(<li className="page-item" key="previous"><a href="#!" onClick={() => props.goToPage(previousPage)} className="page-link"><span aria-hidden="true">‹</span><span className="visually-hidden">Previous</span></a></li>);
    }

    // Pages in middle
    for (let i = 1; i <= props.numberOfPages; i++) {

        // Current page
        if (i === props.currentPage) {
            items.push(
                <li className="page-item active" key={i}>
                    <span className="page-link">
                        {i}<span className="visually-hidden">(current)</span>
                    </span>
                </li>
            );
            dots = true;
        }
        // Other pages
        else {
            // Show page link
            if (
                i <= endSize ||
                (
                    props.currentPage && i >= props.currentPage - midSize &&
                    i <= props.currentPage + midSize
                ) ||
                i > props.numberOfPages - endSize
            ) {
                items.push(<li className="page-item" key={i}><a href="#!" onClick={() => props.goToPage(i)} className="page-link">{i}</a></li>);
                dots = true;
            }
            // Show dots
            else if (dots) {
                items.push(<li className="page-item" key="dots"><span className="page-link" tabIndex="0"><span aria-hidden="true">…</span><span className="visually-hidden">More</span></span></li>);
                dots = false;
            }

        }

    }

    // Next page and Last page
    if (props.currentPage < props.numberOfPages) {
        const nextPage = props.currentPage + 1;
        items.push(<li className="page-item" key="next"><a href="#!" onClick={() => props.goToPage(nextPage)} className="page-link"><span aria-hidden="true">›</span><span className="visually-hidden">Next</span></a></li>);
        items.push(<li className="page-item" key="last"><a href="#!" onClick={() => props.goToPage(props.numberOfPages)} className="page-link"><span aria-hidden="true">»</span><span className="visually-hidden">Last</span></a></li>);
    }

    return (
        <div className={`d-flex justify-content-between ${classes.paginationList}`}>
            {props.total && <div className="pagination-left">Ukupno {props.total} rezultata</div>}
            <ul className="pagination">
                {items}
            </ul>
        </div>
    );
};

export default IncomeExpansesPagination;