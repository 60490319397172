import React from 'react';
import classes from './InputCheckbox.module.css'

const InputCheckbox = props => {

    const handleOnClick = (e) => {
        const inputField = e.currentTarget.getElementsByTagName("input")[0];
        if(!inputField.disabled){
            inputField.checked = (inputField.checked)? false : true;
        }
    };

    const inputClasses = props.styles ? props.styles + classes.input_over : classes.input_over;

    return (
        <div className={inputClasses}>
            <label htmlFor={props.name} className={classes.form_label} onClick={handleOnClick}>{props.label}
                <input
                    type="checkbox"
                    id={props.name}
                    aria-describedby={`${props.name}Help`}
                    defaultChecked={props.checked}
                    disabled={props.disabled}
                    value={props.value}
                />
                <span className={classes.checkmark}></span>
            </label>
        </div>
    );
};

export default InputCheckbox;