import React from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalConfirm = (props) => {

    const navigate = useNavigate();

    return (
        <Modal id='modal-delete' show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{props.text}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.hide}>Zatvori</Button>
                <Button variant="danger" onClick={() => navigate(props.navigate)}>Izbriši</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirm;