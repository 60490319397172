import { fetchWrapperClass } from './fetchWrapper';
import { projectUrl, projectDataUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getProjectsData = async (token, clientID) => {
    return await fetchWrapperUser.get(projectUrl + '/' + clientID + '/projects', token).then(data => { return data; });
};

export const postNewProjectData = async (postData, clientID, token) => {
    return await fetchWrapperUser.post(projectUrl + '/' + clientID + '/projects', token, postData).then(data => { return data; });
};

export const getOneProjectData = async (token, projectID, clientID) => {
    return await fetchWrapperUser.get(projectUrl + '/' + clientID + '/projects/' + projectID, token).then(data => { return data; });
};

export const postEditProjectData = async (postData, projectID, clientID, token) => {
    return await fetchWrapperUser.put(projectUrl + '/' + clientID + '/projects/' + projectID, token, postData).then(data => { return data; });
};

export const getProjectCategoriesData = async (token, projectID, clientID) => {
    return await fetchWrapperUser.get(projectDataUrl + '/categories/' + clientID + '/' + projectID, token).then(data => { return data; });
};

export const getProjectYearsData = async (token, projectID, clientID) => {
    return await fetchWrapperUser.get(projectDataUrl + '/years/' + clientID + '/' + projectID, token).then(data => { return data; });
};

export const getProjectYearTotalsData = async (token, projectID, clientID, currentYear) => {
    return await fetchWrapperUser.get(projectDataUrl + '/yeartotal/' + clientID + '/' + projectID + '/' + currentYear, token).then(data => { return data; });
};

export const getProjectCategoriesIncomeOrExpanse = async (token, projectID, clientID, incomeExpanse) => {
    return await fetchWrapperUser.get(projectDataUrl + '/categoriesIncomeExpanse/' + clientID + '/' + projectID + '/' + incomeExpanse, token).then(data => { return data; });
};