import { removeSessionLocalStorage } from '.';

export const fetchWrapperClass = () => {

    // Helper functions
    const authHeader = (url, token) => {

        // Return auth header with jwt if user is logged in and request is to the api url
        const isApiUrl = url.includes('api');

        if (token && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }

    const handleResponse = (response) => {

        // Check for 404 or 405
        if ( !response.ok && (response.status === 404 || response.status === 405) ) {
            return false;
        }

        // Return data
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    console.log('krivi link!!!! ');
                    //removeSessionLocalStorage();
                    //window.location.href = 'login';
                }

                return false;
            }

            return data;
        });
    }

    const request = (method) => {
        
        return (url, token, body, isFile = false) => {
            const requestOptions = {
                method,
                headers: authHeader(url, token),
                body: body
            };
            if (body && !isFile) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            return fetch(url, requestOptions).then(handleResponse);
        }
    };


    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };
}