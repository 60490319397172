import Recoil, { atom, selectorFamily, selector } from "recoil";
import { tokenState } from ".";
import {
    getBasicCategoriesData,
    getOneBasicCategoryData
} from "../services";

export const incomeExpanseState = atom({
    key: 'incomeExpanse',
    default: [
        ['I', 'Priljev'],
        ['E', 'Odljev'],
    ]
});

export const basicCategoryTriggerState = atom({
    key: 'basicCategoryTrigger',
    default: 0
});

export const basicCategoriesState = selector({
    key: 'basicCategories',
    get: async ({ get }) => {
        get(basicCategoryTriggerState);
        const response = await getBasicCategoriesData(get(tokenState));

        return response;
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(basicCategoryTriggerState, v => v + 1);
        }
    }
});

export const basicCategoryEditState = selectorFamily({
    key: 'basicCategoryEdit',
    get: (id) => async ({ get }) => {
        const response = await getOneBasicCategoryData(get(tokenState), id);

        return response.data;
    }
});