import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import LoginLeft from '../components/Login/LoginLeft';
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm';

import { currentUserProfileState } from '../store/user';

const ResetPassword = () => {

    const userData = useRecoilValue(currentUserProfileState);
    const navigate = useNavigate();

    // Redirect to home if already logged in
    useEffect(() => {
        if (userData) {
            navigate('/');
        }
    }, [userData, navigate]);


    return (
        <div className="d-flex align-content-center h-100 login-main flex-grow-1">
            <LoginLeft />
            <ResetPasswordForm />
        </div>
    );
}

export default ResetPassword;