import { fetchWrapperClass } from './fetchWrapper';
import { incomeExpanseUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getIncomeExpansesData = async (clientID, projectID, token, page) => {
    return await fetchWrapperUser.get(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse?page=' + page, token).then(data => { return data; });
};

export const postAddInputExpanseData = async (postData, projectID, clientID, token) => {
    return await fetchWrapperUser.post(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse', token, postData).then(data => { return data; });
};

export const getOneIncomeExpanseData = async (clientID, projectID, token, id) => {
    return await fetchWrapperUser.get(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse/' + id, token).then(data => { return data; });
};

export const postEditInputExpanseData = async (postData, projectID, clientID, token, incomeExpanseID) => {
    return await fetchWrapperUser.put(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse/' + incomeExpanseID, token, postData).then(data => { return data; });
};

export const deleteEditInputExpanseData = async (incomeExpanseID, projectID, clientID, token) => {
    return await fetchWrapperUser.delete(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse/' + incomeExpanseID, token).then(data => { return data; });
};

export const getIncomeExpansesSearchData = async (enteredSearch, projectID, clientID, token) => {
    return await fetchWrapperUser.get(incomeExpanseUrl + '/' + clientID + '/' + projectID + '/income_expanse/search?searchValue=' + enteredSearch, token).then(data => { return data; });
};
