import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";

import { tokenState, currentUserProfileState, successState } from './store';

import { getUserData } from './services/userServices';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Header from "./components/Layout/Header";
import MainLayout from "./components/Layout/MainLayout";
import Logout from "./components/Logout/Logout";

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import ClientProjects from './pages/ClientProjects';
import ProjectDashboard from './pages/ProjectDashboard';
import { UsersIndex, Users, AddNewUser, EditUser, DeleteUser } from './pages/SuperAdmin/ManageUsers';
import { ClientsIndex, Clients, AddNewClient, EditClient, DeleteClient, ClientsAccess } from './pages/SuperAdmin/Clients';
import { BasicCategoriesIndex, BasicCategories, AddNewBasicCategory, EditBasicCategory, DeleteBasicCategory } from './pages/SuperAdmin/BasicCategories';
import { ProjectsIndex, AddNewProject, EditProject, DeleteProject } from './pages/Projects';
import { BankStatementsIndex, BankStatements, AddNewBankStatement, DeleteBankStatement, EditBankStatement } from './pages/BankStatements';
import Success from './components/elements/Success';

import ChangeName from './pages/ChangeName';

const App = () => {

	const token = useRecoilValue(tokenState);
	const success = useRecoilValue(successState);
	const userData = useRecoilValue(currentUserProfileState);
	const location = useLocation();

	// Check if we have token in local storage
	useEffect(() => {

		// Verify token is valid
		const verifyToken = async (token) => {

			const userDataCheck = await getUserData(token);

			if (userDataCheck === false) {
				localStorage.removeItem('sessionUserData');
				return <Navigate to="/login" state={{ from: location }} />;
			}

		};

		// Verify token but not for login and logout page
		if (location.pathname !== '/login' && location.pathname !== '/logout' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') {
			verifyToken(token);
		}

	}, [location, userData, token]);

	// If we not have token navigate to login
	if (!token && location.pathname !== '/login' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') {
		return <Navigate to="/login" state={{ from: location }} />;

	}

	return (

		<div className="app">
			{location.pathname !== '/login' && token && (<Suspense fallback={<div>Loading....</div>}><Header /></Suspense>)}
			<MainLayout>
				<Suspense fallback={<div>Loading....</div>}>
					<Routes>
						<Route path="login" exact element={<Login />} />
						<Route path="logout" exact element={<Logout />} />
						<Route path="forgot-password" exact element={<ForgotPassword />} />
						<Route path="reset-password" exact element={<ResetPassword />} />
						<Route path="/" exact element={<Dashboard />} />

						<Route path="change" exact element={<ChangeName />} />

						<Route path="client-projects/:id" exact element={<ClientProjects />} />
						<Route path="project/:id/:clientID" exact element={<ProjectDashboard />} />

						<Route path="manage-users" element={<UsersIndex />}>
							<Route path="all" element={<Users />} />
							<Route path="all/:page" element={<Users />} />
							<Route path="new/:page" element={<AddNewUser />} />
							<Route path="edit/:id/:page" element={<EditUser />} />
							<Route path="delete/:id/:page" element={<DeleteUser />} />
						</Route>

						<Route path="clients" element={<ClientsIndex />}>
							<Route path="all" element={<Clients />} />
							<Route path="all/:page" element={<Clients />} />
							<Route path="new/:page" element={<AddNewClient />} />
							<Route path="edit/:id/:page" element={<EditClient />} />
							<Route path="delete/:id/:page" element={<DeleteClient />} />
							<Route path="access/:id/:page" element={<ClientsAccess />} />
						</Route>

						<Route path="basic-categories" element={<BasicCategoriesIndex />}>
							<Route path="all" element={<BasicCategories />} />
							<Route path="new" element={<AddNewBasicCategory />} />
							<Route path="edit/:id" element={<EditBasicCategory />} />
							<Route path="delete/:id" element={<DeleteBasicCategory />} />
						</Route>

						<Route path="projects" element={<ProjectsIndex />}>
							<Route path="new/:clientID" element={<AddNewProject />} />
							<Route path="edit/:id/:clientID" element={<EditProject />} />
							<Route path="delete/:id/:clientID" element={<DeleteProject />} />
						</Route>

						<Route path="bank-statements" element={<BankStatementsIndex />}>
							<Route path="all/:clientID" element={<BankStatements />} />
							<Route path="all/:clientID/:page" element={<BankStatements />} />
							<Route path="new/:clientID" element={<AddNewBankStatement />} />
							<Route path="edit/:id/:clientID/:page" element={<EditBankStatement />} />
							<Route path="delete/:id/:clientID/:page" element={<DeleteBankStatement />} />
						</Route>

					</Routes>
				</Suspense>
			</MainLayout>
			{success && <Success />}
		</div>
	);

}

export default App;
