import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import Table from 'react-bootstrap/Table';

import { bankStatementEditState, bankStatementsEntriesState } from "../../store";

import BankStatementHeader from './BankStatementHeader';
import Button from "../../components/UI/Button";
import BankStatementIncomeExpanseAddModal from "../../components/BankStatement/BankStatementIncomeExpanseAddModal";

import { tablePrice } from "../../helpers/project";

export const EditBankStatement = () => {

    const { id, clientID } = useParams();

    const [showIncomeExpanseAddModal, setShowIncomeExpanseAddModal] = useState(false);
    const [entryID, setEntryID] = useState(null);

    const itemData = useRecoilValue(bankStatementEditState({ clientID, id }));
    const items = useRecoilValue(bankStatementsEntriesState({ clientID, id }));

    const navigate = useNavigate();

     // Add income expanse modal functions
     const handleModalIncomeExpanseAdd = (entryID) => {
        setEntryID(entryID);
        setShowIncomeExpanseAddModal(true);
    };

    const handleIncomeExpanseAddModalHide = () => {
        setShowIncomeExpanseAddModal(false);
    };

    const date_bank_statement_created = new Date(itemData.creation_datetime);
    const date_bank_statement_uploaded = new Date(itemData.updated_at);

    return (
        <div className="flex-grow-1">
            <BankStatementHeader />
            <div className="content">
                <Table className="table-list">
                    <thead>
                        <tr>
                            <th>
                                Bankovni izvadak: {itemData.legal_sequence_number}
                                <br />Banka: {itemData.bank_name}
                                <br />ID izvatka: {itemData.message_identification}
                                <br />Datum izvatka: {String(date_bank_statement_created.getDate() + '.' + (date_bank_statement_created.getMonth() + 1) + '.' + date_bank_statement_created.getFullYear())}
                                <br />Datum dodavanja: {String(date_bank_statement_uploaded.getDate() + '.' + (date_bank_statement_uploaded.getMonth() + 1) + '.' + date_bank_statement_uploaded.getFullYear())}
                            </th>
                        </tr>
                    </thead>
                </Table>
                <Table className="table-list">
                    <thead>
                        <tr>
                            <th scope="col">Datum obrade </th>
                            <th scope="col">Platitelj/Primatelj</th>
                            <th scope="col">Opis plaćanja</th>
                            <th scope="col">Isplata</th>
                            <th scope="col">Uplata</th>
                            <th scope="col">Pripada projektu</th>
                            <th scope="col">Opcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items &&
                            items.map((item) => {

                                const booking_date = new Date(item.booking_date);

                                return (
                                    <tr key={item.id}>
                                        <td>{String(booking_date.getDate() + '.' + (booking_date.getMonth() + 1) + '.' + booking_date.getFullYear())}</td>
                                        <td>{item.party_identify}</td>
                                        <td>{item.description_payment}</td>
                                        <td>{item.income_expanse === 'E' ? tablePrice(item.amount) : ''}</td>
                                        <td>{item.income_expanse === 'I' ? tablePrice(item.amount) : ''}</td>
                                        <td>
                                            {!item.project_income_expense_item_id && <Button styles="btn btn-light-blue" onClick={() => {
                                                handleModalIncomeExpanseAdd(item.id);
                                            }}>Pridruži projektu</Button>}
                                            {item.project_income_expense_item_id && <a href={`/project/${item.projectSlug}/${clientID}/`}>{item.projectName}</a>}
                                        </td>
                                        <td>opcije</td>

                                    </tr>
                                );
                            })
                        }
                        {items && items.length === 0 &&
                            <tr>
                                <td colSpan="4">Nemate unesenih stavki bankovnog izvatka.</td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <div className="text-center pt-4">
                    <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Povratak</Button>
                </div>

            </div>

            {entryID && 
                <BankStatementIncomeExpanseAddModal
                    show={showIncomeExpanseAddModal}
                    hide={handleIncomeExpanseAddModalHide}
                    entryID={entryID}
                    clientID={clientID}
                    bankStatementID={id}
                />
            }

        </div>
    );
};