import { useEffect, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { currentUserPermissionsState, tokenState, successState, bankStatementEditState, bankStatementsState } from "../../store";
import { deleteBankStatementData } from "../../services";

export const DeleteBankStatement = () => {

    const { id, clientID, page } = useParams();

    const itemData = useRecoilValue(bankStatementEditState({ clientID, id }));
    const itemsReset = useResetRecoilState(bankStatementsState({ clientID, page }));

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    const deleteItem = useCallback(async (id) => {

        const response = await deleteBankStatementData(clientID, id, token);

        if (response.success) {

            itemsReset(v => v + 1);

            setSuccess(response.message);

            navigate(`/bank-statements/all/${clientID}/1`);
        }
        else {
            navigate(`/bank-statements/all/${clientID}/1`);
        }
    }, [navigate, setSuccess, token, itemsReset, clientID]);

    // Check user permission and delete it
    useEffect(() => {

        if (id && itemData) {
            deleteItem(itemData.id);
        }

    }, [userPermissions, navigate, id, itemData, deleteItem]);

    return null;

};