import React from 'react';
import Card from 'react-bootstrap/Card';

import classes from './CardBox.module.css'

const CardBox = (props) => {

    const cardClasses = props.styles ? props.styles + classes.card + ' mb-2' : classes.card + ' mb-2';

    return (
        <Card className={cardClasses}>
            <Card.Header className={classes.cardHeader}>{props.title}</Card.Header>
            {props.children && (
                <Card.Body className={classes.cardBody}>
                    {props.children}
                </Card.Body>
            )}
            <Card.Footer className={classes.cardFooter}>
                {props.footer}
            </Card.Footer>
        </Card>
    );
};

export default CardBox;