import { fetchWrapperClass } from './fetchWrapper';
import { categoriesUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const postEditCategoryData = async (postData, id, projectID, clientID, token) => {
    return await fetchWrapperUser.put(categoriesUrl + '/' + clientID + '/' + projectID + '/category/' + id, token, postData).then(data => { return data; });
};

export const postAddCategoryData = async (postData, projectID, clientID, token) => {
    return await fetchWrapperUser.post(categoriesUrl + '/' + clientID + '/' + projectID + '/category', token, postData).then(data => { return data; });
};

export const deleteCategoryData = async (id, projectID, clientID, categoryReplaceID, token) => {
    return await fetchWrapperUser.delete(categoriesUrl + '/' + clientID + '/' + projectID + '/category/' + id + '/' + categoryReplaceID, token).then(data => { return data; });
};