import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useRecoilValue } from "recoil";

import { userClientsState } from "../store";

import HeaderBottom from "../components/Layout/HeaderBottom";
import CardBox from "../components/UI/CardBox";

const Dashboard = () => {

    const allClients = useRecoilValue(userClientsState);

    return (
        <div className="flex-grow-1">
            <HeaderBottom>
                <div className="headerLeft">
                    <h2 className="d-inline-block">
                        <Link to={`/`}>Upravljačka ploča</Link>
                    </h2>
                </div>
            </HeaderBottom>
            <div className="content">
                <Row xs={1} md={3} className="g-4">
                    {allClients.map((option, i) => {
                        // const footerData = <Link to={`/client-projects/${option[0]}`}>Projekti</Link>;

                        const footerData = (
                            <div className="d-flex justify-content-between">
                                <Link to={`/client-projects/${option[0]}`}>Projekti</Link>
                                <Link to={`/bank-statements/all/${option[0]}`}>Bankovni izvadci</Link> 
                            </div>
                        );
                        
                        return (
                            <Col key={i}>
                                <CardBox title={option[1]} footer={footerData}>
                                    <strong>Adresa: </strong> {option[2]}<br />
                                    <strong>OIB: </strong> {option[3]}
                                </CardBox>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};

export default Dashboard;