import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { validateEmptyInput } from '../../helpers/inputValidate';
import { tokenState, successState, projectsState, basicCategoriesState } from "../../store";
import { postNewProjectData } from "../../services";

import ProjectsHeader from './ProjectsHeader';
import FormElement from "../../components/elements/FormElement";

import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import InputCheckbox from '../../components/UI/InputCheckbox';

export const AddNewProject = () => {

    const { clientID } = useParams();
    const token = useRecoilValue(tokenState);
    const basicCategories = useRecoilValue(basicCategoriesState);
    const itemsReset = useResetRecoilState(projectsState(clientID));
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const endDateInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv projekta.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredEndDate = endDateInputRef.current.value;

        let checkedValues = [];

        event.target.has_categories.forEach((checkbox) => {
            if (checkbox.checked) {
                checkedValues.push(checkbox.value);
            }
        });

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postNewProjectData({ enteredName, enteredEndDate, clientID, checkedValues }, clientID, token );

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                itemsReset(v => v + 1);

                setSuccess(response.message);

                navigate('/client-projects/' + clientID);
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <ProjectsHeader>
                <span className="d-inline-block ps-1">• Novi projekt</span>
            </ProjectsHeader>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Dodaj novi projekt" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Naziv projekta"
                        autoComplete="off"
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={endDateInputRef}
                        name="end-date"
                        type="date"
                        label="Projekt traje do datuma"
                        autoComplete="off"
                        error={null}
                    />
                    {basicCategories && <div className="mb-2"><label>Projekt će imati osnovne kategorije:</label></div>}  
                    {basicCategories && basicCategories.data.map((option, i) => {
                        return (
                            <div key={i}>
                                <InputCheckbox
                                    id={i}
                                    name="has_categories"
                                    checked="checked"
                                    value={option.slug}
                                    label={option.name}
                                />
                            </div>
                        );
                    })}
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Cancel</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Dodaj novi projekt</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};