import React from 'react';
import classes from './Input.module.css'

const Input = React.forwardRef((props, ref) => {

    const inputClasses = props.styles ? props.styles + classes.input_over : classes.input_over;

    return (
        <div className={inputClasses}>
            <label htmlFor={props.name} className={`form-label ${classes.form_label}`}>
                {props.label}
                {props.additionalLabel &&
                    <div className={classes.additionalLabel}>{props.additionalLabel}</div>
                }
            </label>
            <input 
                ref={ref}
                type={props.type}
                className={`form-control ${classes.form_control}`}
                id={props.name}
                aria-describedby={`${props.name}Help`}
                defaultValue={props.defaultValue}
                minLength={props.minLength}
                maxLength={props.maxLength}
                step={props.step}
                disabled={props.disabled}
            />
            {props.help &&
                <div id={`${props.name}Help`} className="form-text">{props.help}</div>
            }
            {props.error &&
                <div id={`${props.name}Error`} className={`form-text ${classes.form_error_text}`}>{props.error}</div>
            }
        </div>
    );
});

export default Input;