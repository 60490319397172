import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilValue } from "recoil";

import { clientState } from "../../store";

import HeaderBottom from "../../components/Layout/HeaderBottom";

const BankStatementHeader = ({ children }) => {

    const { clientID } = useParams();
    const clientData = useRecoilValue(clientState(clientID));

    return (
        <HeaderBottom>
            <div className="headerLeft">
                <h2 className="d-inline-block me-1">
                    <Link to={`/`}>Upravljačka ploča</Link>
                </h2><span className="d-inline-block ps-1">• <em>{clientData.name}</em> • <Link to={`/bank-statements/all/${clientID}`}>Bankovni izvadci</Link></span>
                {children}
            </div>
            <div className="headerRight">
                <Link to={`/bank-statements/new/${clientID}`} className="btn btn-light-blue">Dodaj novi bankovni izvadak</Link>
            </div>
        </HeaderBottom>
    );
};

export default BankStatementHeader;