import { useEffect, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { currentUserPermissionsState, tokenState, successState, basicCategoriesState, basicCategoryEditState } from "../../../store";
import { deleteBasicCategoryData } from "../../../services";

export const DeleteBasicCategory = () => {

    const {id} = useParams()

    const itemData = useRecoilValue(basicCategoryEditState(id));
    const itemsReset = useResetRecoilState(basicCategoriesState);

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    const deleteItem = useCallback(async (id) => {

        const response = await deleteBasicCategoryData(id, token);
    
            if( response.success ){
    
                itemsReset(v => v +1);
    
                setSuccess(response.message);

                navigate('/basic-categories/all');
            }
            else{
                navigate('/basic-categories/all');
            }
    }, [navigate, setSuccess, token, itemsReset]);

    // Check user permission and delete it
    useEffect(() => {

        if( id && itemData ){
            deleteItem(itemData.slug);
        }

        if (!userPermissions.includes('manage_basic_categories')) { navigate('/'); }

    }, [userPermissions, navigate, id, itemData, deleteItem]);

    return null;

};