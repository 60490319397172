import React, { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { projectCategoriesState } from '../../../store';

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { tokenState, successState, projectCurrentYearState, projectYearTotalsState } from "../../../store";
import { postAddInputExpanseData } from "../../../services";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../../elements/FormElement';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import CategorySelect from "../Category/CategorySelect";

import classes from './IncomeExpanseModal.module.css';

const IncomeExpanseAddModal = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const currentYear = useRecoilValue(projectCurrentYearState);
    const yearTotalsReset = useResetRecoilState(projectYearTotalsState({ projectID, clientID, currentYear }));

    const projectCategories = useRecoilValue(projectCategoriesState);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const projectCategoryIDInputRef = useRef();
    const amountInputRef = useRef();
    const dateInputRef = useRef();

    let newDate = new Date()
    let date = ('0' + newDate.getDate()).slice(-2);
    let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    let year = newDate.getFullYear();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv osnovne kategorije.';

    const [errorAmount, setErrorAmount] = useState(false);
    const errorAmountMessage = 'Upišite iznos veći od nule..';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredProjectCategoryID = projectCategoryIDInputRef.current.value;
        const enteredAmount = amountInputRef.current.value;
        const enteredDate = dateInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        if (!validateEmptyInput(enteredAmount) || parseFloat(enteredAmount) <= 0) {
            setErrorAmount(true);
            globalError = true;
        }
        else {
            setErrorAmount(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postAddInputExpanseData({ enteredName, enteredProjectCategoryID, enteredAmount, enteredDate }, projectID, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);

                yearTotalsReset(v => v + 1);

                props.hide();
                props.added();
            }

        }

    }

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>
                <Modal.Title>Dodajte priljev/odljev</Modal.Title>
            </Modal.Header>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <Modal.Body>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Naziv Priljeva / Odljeva"
                        autoComplete="off"
                        error={errorName ? errorNameMessage : null}
                    />
                    <CategorySelect
                        ref={projectCategoryIDInputRef}
                        name="project_category_id"
                        label="Priljev / Odljev pripada kategoriji"
                        autoComplete="off"
                        options={projectCategories}
                        error={null}
                    />
                    <Input
                        ref={amountInputRef}
                        name="amount"
                        type="number"
                        label="Iznos"
                        autoComplete="off"
                        defaultValue="1.00"
                        step="0.01"
                        error={errorAmount ? errorAmountMessage : null}
                    />
                    <Input
                        ref={dateInputRef}
                        name="date"
                        type="date"
                        label="Datum troška"
                        autoComplete="off"
                        defaultValue={year + '-' + month + '-' + date}
                        error={null}
                    />


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Zatvori</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Dodaj priljev/odljev</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default IncomeExpanseAddModal;