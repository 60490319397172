import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useResetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

import { validateEmptyInput } from '../../helpers/inputValidate';
import { tokenState, successState, projectsState, projectEditState } from "../../store";
import { postEditProjectData } from "../../services";

import ProjectsHeader from './ProjectsHeader';
import FormElement from "../../components/elements/FormElement";

import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';

export const EditProject = () => {

    const { id, clientID } = useParams();
    const itemEditData = useRecoilValue(projectEditState({ id, clientID }));
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    // Reset states
    const resetItemsPage = useResetRecoilState(projectsState(clientID));
    const resetItem = useRecoilRefresher_UNSTABLE(projectEditState({ id, clientID }));

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const endDateInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv projekta.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredEndDate = endDateInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        // Save edit data
        if (
            !globalError
        ) {

            const response = await postEditProjectData({ enteredName, enteredEndDate, clientID }, id, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                resetItem();
                resetItemsPage();

                setSuccess(response.message);

                navigate(-1);
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <ProjectsHeader>
                <span className="d-inline-block ps-1">• Novi projekt</span>
            </ProjectsHeader>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Izmjeni projekt" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Naziv projekta"
                        autoComplete="off"
                        defaultValue={itemEditData.name}
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={endDateInputRef}
                        name="end-date"
                        type="date"
                        label="Projekt traje do datuma"
                        autoComplete="off"
                        defaultValue={itemEditData.end_date}
                        error={null}
                    />
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Cancel</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Sačuvaj izmjene</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};