import React, { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate, Link } from 'react-router-dom';

import classes from './LoginRight.module.css'

import Input from '../UI/Input';
import Button from '../UI/Button';

import { validateEmail, validateEmptyInput } from '../../helpers/inputValidate';
import { doUserLogin, saveSessionLocalStorage } from '../../services';
import { tokenState, currentUserProfileState } from '../../store';

const LoginRight = () => {

    /* State variables */
    const setToken = useSetRecoilState(tokenState);
    const setUserData = useSetRecoilState(currentUserProfileState);

    let navigate = useNavigate();

    /* Form login part with validation */
    let globalError = false;
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const [errorEmail, setErrorEmail] = useState(false);
    const errorEmailMessage = 'Upišite ispravnu email adresu.';

    const [errorPassword, setErrorPassword] = useState(false);
    const errorPasswordMessage = 'Upišite ispravnu lozinku.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        // Validate email and password
        if (!validateEmail(enteredEmail)) {
            setErrorEmail(true);
            globalError = true;
        }
        else {
            setErrorEmail(false);
        }

        if (!validateEmptyInput(enteredPassword)) {
            setErrorPassword(true);
            globalError = true;
        }
        else {
            setErrorPassword(false);
        }

        // Log user if everything ok
        if (!globalError) {
            const response = await doUserLogin(enteredEmail, enteredPassword);

            if (!response) {
                setErrorEmail(true);
                setErrorPassword(true);
            }
            else { // Log in user if no errors, save all neccessary information

                setToken(response.token);
                setUserData(response.data);

                saveSessionLocalStorage(response);

                navigate('/');
            }

        }

    }

    return (
        <div className={`d-flex flex-grow-1 h-100 justify-content-center ${classes.login_right}`}>
            <div className="d-flex flex-column justify-content-center">
                <form className="form" onSubmit={handleFormSubmit}>
                    <div className={`${classes.form_header} form-header mb-5 text-center`}>
                        <h2 className="mb-3">Prijavite se</h2>
                        <div>
                            Nemate pristup? <a href="mailto:poduzetnicki.centar@synergia-consulting.hr" className="link-blue">Kontaktirajte korisničku podršku</a>
                        </div>
                    </div>
                    <Input
                        ref={emailInputRef}
                        name="email"
                        type="email"
                        label="Tvoj email"
                        autoComplete="off"
                        error={errorEmail ? errorEmailMessage : null}
                    />
                    <Input
                        ref={passwordInputRef}
                        name="password"
                        type="password"
                        label="Lozinka"
                        autoComplete="off"
                        error={errorPassword ? errorPasswordMessage : null}
                        additionalLabel={<Link to="/forgot-password" className="link-blue">Zaboravio si lozinku?</Link>}
                    />
                    <div className="text-center">
                         <Button
                            name="submit"
                            type="submit"
                         >
                            Prijavi se
                         </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginRight;