import React from 'react';
import Logo from '../UI/Logo';
import classes from './LoginLeft.module.css';

const LoginLeft = ({ children }) => {
    return (
        <div className={`d-flex ${classes.login_left}`}>
            <div className="text-center align-self-center">
                <Logo styles={classes.logo} />
                <h1>Dobrodošli u FINAP!</h1>
                <p>
                    Da bi ste započeli koristiti aplikaciju logirajte se.
                </p>
            </div>
        </div>
    );
};

export default LoginLeft;