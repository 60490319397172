import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil'
import App from './App';
import Footer from './components/Layout/Footer';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<RecoilRoot>
		<BrowserRouter>
			<Suspense fallback={<div>Loading....</div>}>
				<App />
				<Footer />
			</Suspense>
		</BrowserRouter>
	</RecoilRoot>);