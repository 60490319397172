import React from 'react';
import classes from './ProjectsSelect.module.css'

const ProjectsSelect = React.forwardRef((props, ref) => {

    const inputClasses = props.styles ? props.styles + classes.input_over : classes.input_over;

    return (
        <div className={inputClasses}>
            <label htmlFor={props.name} className={`form-label ${classes.form_label}`}>
                {props.label}
                {props.additionalLabel &&
                    <div className={classes.additionalLabel}>{props.additionalLabel}</div>
                }
            </label>
            <select 
                ref={ref}
                type={props.type}
                className={`form-control ${classes.form_control}`}
                id={props.name}
                aria-describedby={`${props.name}Help`}
                defaultValue={props.defaultValue}
                onChange={props.onChangeHandler}
            >
                {props.options.map((option, i) => {
                    return <option key={i} value={option['slug']}>{option['name']}</option>
                })}
            </select>
            {props.help &&
                <div id={`${props.name}Help`} className="form-text">{props.help}</div>
            }
            {props.error &&
                <div id={`${props.name}Error`} className={`form-text ${classes.form_error_text}`}>{props.error}</div>
            }
        </div>
    );
});

export default ProjectsSelect;