import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { useRecoilValue } from "recoil";

import { clientState, projectEditState } from "../store";

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import HeaderBottom from "../components/Layout/HeaderBottom";

import ProjectTable from '../components/Project/ProjectTable/ProjectTable';
import IncomeExpanses from '../components/Project/IncomeExpanse/IncomeExpanses';
import CategoryList from '../components/Project/Category/CategoryList';

import classes from './ProjectDashboard.module.css';

const ProjectDashboard = () => {

    const { id, clientID } = useParams();

    const projectData = useRecoilValue(projectEditState({ id, clientID }));
    const clientData = useRecoilValue(clientState(clientID));
    const [incomeExpansePage, setIncomeExpansePage] = useState(1);

    const changeIncomeExpansePage = (newPage) => {
        setIncomeExpansePage(newPage);
    };

    return (
        <div className={`flex-grow-1 ${classes.projectDashboard}`}>
            <HeaderBottom>
                <div className="headerLeft">
                    <h2 className="d-inline-block">
                        <Link to={`/`}>Upravljačka ploča</Link>
                    </h2>
                    <span className="d-inline-block ps-1">• <em>{clientData.name}</em> • </span>
                    <Link to={`/client-projects/${clientID}`} className="ps-1">Projekti</Link>
                    <span className="d-inline-block ps-1">• <em>{projectData.name}</em></span>
                </div>
                <div className="headerRight">
                    <Link to={`/projects/new/${clientID}`} className="btn btn-light-blue">Dodaj novi projekt</Link>
                </div>
            </HeaderBottom>
            <div className="content">
                <h1 className="d-flex align-items-center">
                    <span>{projectData.name}</span>
                    <Link to={`/projects/edit/${id}/${clientID}`} className="btn-edit col-auto ms-3">Uredi projekt</Link>
                </h1>
                <Tabs
                    id="controlled-tab-example"
                    className="mb-3 tabsMain"
                >
                    <Tab eventKey="projectHome" title="Tablica">
                        <ProjectTable projectID={id} clientID={clientID} />
                    </Tab>
                    <Tab eventKey="incomeExpanse" title="Priljevi/Odljevi projekta">
                        <IncomeExpanses projectID={id} clientID={clientID} goToPage={changeIncomeExpansePage} page={incomeExpansePage} />
                    </Tab>
                    <Tab eventKey="contact" title="Kategorije projekta">
                        <CategoryList projectID={id} clientID={clientID} />
                    </Tab>
                </Tabs>




            </div>
        </div>
    );
};

export default ProjectDashboard;