import { fetchWrapperClass } from './fetchWrapper';
import { clientUrl, dashboardUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getAllClientsData = async (token) => {
    return await fetchWrapperUser.get(clientUrl + '/all_clients', token).then(data => { return data; });
};

export const getClientsData = async (token, page) => {
    return await fetchWrapperUser.get(clientUrl + '?page=' + page, token).then(data => { return data; });
};

export const getOneClientData = async (token, id) => {
    return await fetchWrapperUser.get(clientUrl + '/' + id, token).then(data => { return data; });
};

export const postNewClientData = async (postData, token) => {
    return await fetchWrapperUser.post(clientUrl, token, postData).then(data => { return data; });
};

export const postEditClientData = async (postData, id, token) => {
    return await fetchWrapperUser.put(clientUrl + '/' + id, token, postData).then(data => { return data; });
};

export const postClientClientData = async (postData, id, token) => {
    return await fetchWrapperUser.post(clientUrl + '/clients/' + id, token, postData).then(data => { return data; });
};

export const deleteClientData = async (id, token) => {
    return await fetchWrapperUser.delete(clientUrl + '/' + id, token).then(data => { return data; });
};

export const getUserClientsData = async (token) => {
    return await fetchWrapperUser.get(dashboardUrl + '/user-clients', token).then(data => { return data; });
};

export const getOneUserClientData = async (token, id) => {
    return await fetchWrapperUser.get(dashboardUrl + '/client-data/' + id, token).then(data => { return data; });
};