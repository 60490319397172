import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBottom from "../../../components/Layout/HeaderBottom";

const BasicCategoryHeader = ({ children }) => {

    return (
        <HeaderBottom>
            <div className="headerLeft">
                <h2 className="d-inline-block">
                    <Link to={`/basic-categories/all/`}>Osnovne kategorije</Link>
                </h2>
                {children}
            </div>
            <div className="headerRight">
                <Link to={`/basic-categories/new/`} className="btn btn-light-blue">Dodaj novu osnovnu kategoriju</Link>
            </div>
        </HeaderBottom>
    );
};

export default BasicCategoryHeader;