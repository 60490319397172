import React from 'react';
import { useResetRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { projectYearTotalsState, projectCurrentYearState, tokenState, successState, errorState } from "../../../store";
import { deleteEditInputExpanseData } from "../../../services";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const IncomeExpanseDeleteModal = (props) => {


    const projectID = props.projectID;
    const clientID = props.clientID;

    console.log(projectID, clientID);

    const token = useRecoilValue(tokenState);
    const currentYear = useRecoilValue(projectCurrentYearState);
    const setSuccess = useSetRecoilState(successState);
    const setError = useSetRecoilState(errorState);
    const yearTotalsReset = useResetRecoilState(projectYearTotalsState({ projectID, clientID, currentYear }));

    const deleteIncomeExpanse = async () => {

        if (props.incomeExpanse.id > 0) {

            console.log(props.projectID);
            console.log(props.clientID);

            const response = await deleteEditInputExpanseData(props.incomeExpanse.id, projectID, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setError(errorMessage);
            }
            else {
                setSuccess(response.message);

                yearTotalsReset(v => v + 1);

                props.refreshSearch();
                props.hide();
                props.added();
            }
        }

    };

    return (
        <Modal id='modal-delete' show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>Da li ste sigurni da želite izbrisati {props.incomeExpanse.income_expanse === 'E' ? 'odljev' : 'priljev'} ?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{props.incomeExpanse.name}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.hide}>Odustani</Button>
                <Button variant="danger" onClick={() => deleteIncomeExpanse()}>Izbriši</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IncomeExpanseDeleteModal;