import React, { useState, useRef } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import Table from 'react-bootstrap/Table';
import IncomeExpansesPagination from "./IncomeExpansesPagination";

import { tablePrice } from '../../../helpers/project';
import IncomeExpanseAddModal from './IncomeExpanseAddModal';
import IncomeExpanseEditModal from './IncomeExpanseEditModal';
import IncomeExpanseDeleteModal from './IncomeExpanseDeleteModal';
import { getIncomeExpansesSearchData } from "../../../services";

import {
    incomeExpansesState,
    tokenState
} from '../../../store';

import classes from './IncomeExpanses.module.css';

const IncomeExpanses = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const [showIncomeExpanseAddModal, setShowIncomeExpanseAddModal] = useState(false);
    const [showIncomeExpanseEditModal, setShowIncomeExpanseEditModal] = useState(false);
    const [showIncomeExpanseDeleteModal, setShowIncomeExpanseDeleteModal] = useState(false);
    const [incomeExpanseModalEditData, setIncomeExpanseModalEditData] = useState(null);
    const [incomeExpanseModalDeleteData, setIncomeExpanseModalDeleteData] = useState(null);
    const [serachedItems, setSerachedItems] = useState(null);

    const currentPage = props.page ? props.page : 1;
    const token = useRecoilValue(tokenState);
    const items = useRecoilValue(incomeExpansesState({ clientID, projectID, currentPage }));
    const resetItemsPage = useResetRecoilState(incomeExpansesState({ clientID, projectID, currentPage }));

    // Add income expanse modal functions
    const handleModalIncomeExpanseAdd = () => {
        setShowIncomeExpanseAddModal(true);
    };

    const handleIncomeExpanseAddModalHide = () => {
        setShowIncomeExpanseAddModal(false);
    };

    const handleIncomeExpanseAdded = () => {
        resetItemsPage();
    }

    // Edit income expanse modal functions
    const handleModalIncomeExpanseEdit = (incomeExpanse) => {
        setShowIncomeExpanseEditModal(true);
        setIncomeExpanseModalEditData(incomeExpanse);
    };

    const handleIncomeExpanseEditModalHide = () => {
        setShowIncomeExpanseEditModal(false);
    };

    const handleIncomeExpanseEdited = () => {
        resetItemsPage();
    };

    // Delete income expanse modal functions
    const handleModalIncomeExpanseDelete = (incomeExpanse) => {
        setShowIncomeExpanseDeleteModal(true);
        setIncomeExpanseModalDeleteData(incomeExpanse);
    };

    const handleIncomeExpanseDeleteModalHide = () => {
        setShowIncomeExpanseDeleteModal(false);
    };

    const handleIncomeExpanseDeleted = () => {
        resetItemsPage();
    };

    // Setup search incomes/expanses
    const searchInputRef = useRef();

    const handleInputSearch = async (event) => {
        const enteredSearch = searchInputRef.current.value;

        if(enteredSearch && enteredSearch.length > 2) {
            const response = await getIncomeExpansesSearchData(enteredSearch, projectID, clientID, token);

            if (response.success && response.data.data) {
                setSerachedItems(response.data.data);
            }
        }
        else{
            setSerachedItems(null);
        }
    };

    return (
        <React.Fragment>
            <div className="text-end row justify-content-between align-content-center m-3">
                <div className="col-12 col-md-6">
                    <input ref={searchInputRef} type="text" placeholder="Pretraži priljeve/odljeve, minimalno 3 znaka" className="form-control" onChange={handleInputSearch} />
                </div>
                <div className="col-12 col-md-6">
                    <button
                        onClick={() => {
                            handleModalIncomeExpanseAdd(null);
                        }}
                        className="btn btn-blue"
                    >
                        Dodaj pridljev/odljev
                    </button>
                </div>
            </div>
            <div className="content">
                <Table className={`table-list ${classes.table}`}>
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Priljev/Odljev</th>
                            <th scope="col">Kategorija</th>
                            <th scope="col">Iznos</th>
                            <th scope="col">Datum</th>
                            <th scope="col">Bankovna Transakcija</th>
                            <th scope="col">Opcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!serachedItems && items && items.data &&
                            items.data.map((item) => {

                                const d = new Date(item.date_item_created);
                                const dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + ".";

                                const bankTransDate = new Date(item.creation_datetime);
                                const bankTransDateString = bankTransDate.getDate() + "." + (bankTransDate.getMonth() + 1) + "." + bankTransDate.getFullYear() + ".";

                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    handleModalIncomeExpanseEdit(item);
                                                }}
                                                className="btn btn-blue-nbg"
                                            >
                                                {item.name}
                                            </button>
                                        </td>
                                        <td>{item.category.name}</td>
                                        <td className="text-end">{tablePrice(item.amount)}</td>
                                        <td className="text-end">{dateString}</td>
                                        <td>
                                            {item.party_identify && <div>
                                                {item.party_identify}<br />
                                                {item.description_payment}<br />
                                                {item.bank_name}<br />
                                                {item.legal_sequence_number}<br />
                                                {bankTransDateString}
                                            </div>}
                                            {!item.party_identify && <span>n/a</span>}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => { handleModalIncomeExpanseEdit(item) }}
                                                className="btn btn-blue-nbg"
                                            >
                                                Izmjeni
                                            </button>
                                            <button
                                                onClick={() => { handleModalIncomeExpanseDelete(item) }}
                                                className="ms-3 btn btn-red-nbg"
                                            >Izbriši</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {serachedItems && 
                            serachedItems.map((item) => {

                                const d = new Date(item.date_item_created);
                                const dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + ".";

                                const bankTransDate = new Date(item.creation_datetime);
                                const bankTransDateString = bankTransDate.getDate() + "." + (bankTransDate.getMonth() + 1) + "." + bankTransDate.getFullYear() + ".";

                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    handleModalIncomeExpanseEdit(item);
                                                }}
                                                className="btn btn-blue-nbg"
                                            >
                                                {item.name}
                                            </button>
                                        </td>
                                        <td>{item.category.name}</td>
                                        <td className="text-end">{tablePrice(item.amount)}</td>
                                        <td className="text-end">{dateString}</td>
                                        <td>
                                            {item.party_identify && <div>
                                                {item.party_identify}<br />
                                                {item.description_payment}<br />
                                                {item.bank_name}<br />
                                                {item.legal_sequence_number}<br />
                                                {bankTransDateString}
                                            </div>}
                                            {!item.party_identify && <span>n/a</span>}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => { handleModalIncomeExpanseEdit(item) }}
                                                className="btn btn-blue-nbg"
                                            >
                                                Izmjeni
                                            </button>
                                            <button
                                                onClick={() => { handleModalIncomeExpanseDelete(item) }}
                                                className="ms-3 btn btn-red-nbg"
                                            >Izbriši</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {items && items.data.length === 0 &&
                            <tr>
                                <td colSpan="7">Nemate unesenih klijenata</td>
                            </tr>
                        }
                    </tbody>
                </Table>
                {!serachedItems && items && items.data.length > 0 &&
                    <>
                        <IncomeExpansesPagination
                            goToPage={props.goToPage}
                            currentPage={currentPage}
                            perPage={items.perPage}
                            numberOfPages={items.last_page}
                            endSize="5"
                            total={items.total}
                        />
                    </>
                }
            </div>
            <div className="text-end">
                <button
                    onClick={() => {
                        handleModalIncomeExpanseAdd(null);
                    }}
                    className="btn btn-blue m-3 me-0"
                >
                    Dodaj pridljev/odljev
                </button>
            </div>
            <IncomeExpanseAddModal
                show={showIncomeExpanseAddModal}
                hide={handleIncomeExpanseAddModalHide}
                added={handleIncomeExpanseAdded}
                projectID={projectID}
                clientID={clientID}
            />
            {incomeExpanseModalEditData &&
                <IncomeExpanseEditModal
                    show={showIncomeExpanseEditModal}
                    incomeExpanse={incomeExpanseModalEditData}
                    hide={handleIncomeExpanseEditModalHide}
                    added={handleIncomeExpanseEdited}
                    refreshSearch={handleInputSearch}
                    projectID={projectID}
                    clientID={clientID}
                />
            }
            {incomeExpanseModalDeleteData &&
                <IncomeExpanseDeleteModal
                    show={showIncomeExpanseDeleteModal}
                    incomeExpanse={incomeExpanseModalDeleteData}
                    hide={handleIncomeExpanseDeleteModalHide}
                    added={handleIncomeExpanseDeleted}
                    refreshSearch={handleInputSearch}
                    projectID={projectID}
                    clientID={clientID}
                />
            }
        </React.Fragment>
    );
}

export default IncomeExpanses;