import Recoil, { atom, selectorFamily, selector } from "recoil";
import { tokenState } from ".";
import { 
    getClientsData, 
    getOneClientData, 
    getAllClientsData, 
    getUserClientsData,
    getOneUserClientData
} from "../services";

export const legalFormClientState = atom({
    key: 'legalFormClient',
    default: [
        ['company', 'trgovačko društvo'],
        ['crafts', 'obrt'],
        ['nonprofit-organization', 'neprofitna organizacija'],
    ]
});

export const clientsTriggerState = atom({
    key: 'clientsTrigger',
    default: 0
});

export const clientsState = selectorFamily({
    key: 'clients',
    get: (page) => async ({ get }) => {
        get(clientsTriggerState);
        const response = await getClientsData(get(tokenState), page);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(clientsTriggerState, v => v + 1);
        }
    }
});

export const clientsEditState = selectorFamily({
    key: 'clientsEdit',
    get: (id) => async ({ get }) => {
        const response = await getOneClientData(get(tokenState), id);

        return response.data;
    }
});

export const allClientsState = selector({
    key: 'allClients',
    get: async ({ get }) => {

        let itemsArray = [];
        const response = await getAllClientsData(get(tokenState));
        
        if (response.data != null){
            itemsArray = Object.keys(response.data).map((key) => {
                return [response.data[key].slug, response.data[key].name];
            });
        }

        return itemsArray;
    }
});

/**
 * Get all clients data that user client has access
 */
export const userClientsState = selector({
    key: 'userClients',
    get: async ({ get }) => {
        let itemsArray = [];
        const response = await getUserClientsData(get(tokenState));
        
        if (response.data != null){
            itemsArray = Object.keys(response.data).map((key) => {
                return [response.data[key].slug, response.data[key].name, response.data[key].address, response.data[key].oib];
            });
        }

        return itemsArray;
    }
});

/**
 * Get one client data if user has access to it
 */
export const clientState = selectorFamily({
    key: 'client',
    get: (id) => async ({ get }) => {
        const response = await getOneUserClientData(get(tokenState), id);
        return response.data;
    }
});
