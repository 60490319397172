import Recoil, { atom, selectorFamily } from "recoil";
import { tokenState } from ".";
import { getManageUsersData, getOneUserData } from "../services";

export const userTypesState = atom({
    key: 'userTypes',
    default: [
        ['user', 'Obični korisnik'],
        ['clientadmin', 'Korisnik administrator'],
        ['superadmin', 'Super korisnik'],
    ]
});

export const manageUsersTriggerState = atom({
    key: 'manageUsersTrigger',
    default: 0
});

export const manageUsersState = selectorFamily({
    key: 'manageUsers',
    get: (page) => async ({ get }) => {
        get(manageUsersTriggerState);
        const response = await getManageUsersData(get(tokenState), page);

        return response.data;
    },
    set: () => ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(manageUsersTriggerState, v => v + 1);
        }
    }
});

export const manageUsersEditState = selectorFamily({
    key: 'manageUsersEdit',
    get: (id) => async ({ get }) => {
        const response = await getOneUserData(get(tokenState), id);

        return response.data;
    }
});
