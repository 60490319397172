import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilRefresher_UNSTABLE, useSetRecoilState } from "recoil";
import { currentUserPermissionsState, clientsEditState, allClientsState, tokenState, successState } from "../../../store";
import { postClientClientData } from "../../../services";

import ClientsHeader from './ClientsHeader';
import FormElement from "../../../components/elements/FormElement";

import Button from '../../../components/UI/Button';
import InputCheckbox from '../../../components/UI/InputCheckbox';

export const ClientsAccess = () => {

    const { id, page } = useParams()

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const itemData = useRecoilValue(clientsEditState(id));
    const allClients = useRecoilValue(allClientsState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    // Reset states
    const resetItem = useRecoilRefresher_UNSTABLE(clientsEditState(id));

    // Set up has access to clients data
    let clientAccessData = [];
    if (itemData.clients) {
        clientAccessData = Object.keys(itemData.clients).map((key) => {
            return itemData.clients[key].slug;
        });
    }

    // Check user permission
    useEffect(() => {
        if (!userPermissions.includes('manage_clients')) { navigate('/'); }
    }, [userPermissions, navigate]);

    /* Form add new user part with validation */
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        let checkedValues = [];

        event.target.belongs.forEach((checkbox) => {
            if (checkbox.checked && id !== checkbox.value) {
                checkedValues.push(checkbox.value);
            }
        });

        // Save values
        if (checkedValues) {

            const response = await postClientClientData({ checkedValues }, id, token);

            if (!response.success) {

                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                resetItem(response);

                setSuccess(response.message);

                navigate('/clients/all/' + page);
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <ClientsHeader>
                <span className="d-inline-block ps-1">• Ima pristup klijentima</span>
            </ClientsHeader>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Ima pristup klijentima" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    {allClients.map((option, i) => {
                        let checked = (clientAccessData.includes(option[0])) ? 'checked' : false;
                        let disabled = (option[0] === id) ? 'disabled' : false;
                        return (
                            <div key={i}>
                                <InputCheckbox
                                    id={i}
                                    name="belongs"
                                    value={option[0]}
                                    checked={checked}
                                    disabled={disabled}
                                    label={option[1]}
                                />
                            </div>
                        );
                    })}
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Odustani</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Sačuvaj izmjene</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};