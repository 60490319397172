import React from 'react';
import classes from './FormElement.module.css';

const FormElement = (props) => {

    const classOver = props.classOver ? classes.formOver + ' ' + props.classOver : classes.formOver;

    return (
        <div className={classOver}>
            <form className="form" onSubmit={props.onSubmitHandler}>
                <div className={classes.formHeader}>
                    <h1 className="mb-3">{props.title}</h1>
                    <div className="status">{props.status}</div>
                </div>
                {props.children}
            </form>
        </div>
    );
};

export default FormElement;