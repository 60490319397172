import React, { useRef, useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { validateEmptyInput } from '../../helpers/inputValidate';
import { tokenState, successState, bankStatementsEntryState, projectsState, projectCategoriesIncomeOrExpanseState, bankStatementsEntriesState } from "../../store";
import { postAddBankStatementInputExpanseData } from "../../services";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../elements/FormElement';
import Input from '../UI/Input';
import Button from '../UI/Button';
import ProjectsSelect from '../Project/UI/ProjectsSelect';
import CategorySelect from "../Project/Category/CategorySelect";

import classes from './BankStatementIncomeExpanseModal.module.css';

const BankStatementIncomeExpanseAddModal = (props) => {

    const bankStatementID = props.bankStatementID;
    const clientID = props.clientID;
    const entryID = props.entryID ? props.entryID : null;

    const [incomeExpanseMark, setIncomeExpanseMark] = useState(null);
    const [selectedProjectID, setSelectedProjectID] = useState(null);

    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const entryData = useRecoilValue(bankStatementsEntryState({ clientID, entryID }));
    const clientProjects = useRecoilValue(projectsState(clientID));
    const projectCategories = useRecoilValue(projectCategoriesIncomeOrExpanseState({ selectedProjectID, clientID, incomeExpanseMark }));
    const itemsReset = useResetRecoilState(bankStatementsEntriesState({ clientID, bankStatementID }));

    useEffect(() => {
        if (clientProjects && entryData) {
            setSelectedProjectID(clientProjects[0]['slug']);
            setIncomeExpanseMark(entryData.income_expanse);
        }
    }, [clientProjects, entryData]);

    const handleSelectProjectChange = () => {
        setSelectedProjectID(projectIDInputRef.current.value);
        setIncomeExpanseMark(entryData.income_expanse);
    }

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const bankStatementIDRef = useRef();
    const nameInputRef = useRef();
    const projectIDInputRef = useRef();
    const projectCategoryIDInputRef = useRef();
    const amountInputRef = useRef();
    const dateInputRef = useRef();


    const [errorEnityID, setErrorEnityID] = useState(false);
    const errorEnityIDMessage = 'Stavka bankovnog izvatka nije odabrana.';

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv osnovne kategorije.';

    const [errorProjectID, setErrorProjectID] = useState(false);
    const errorProjectIDMessage = 'Izaberite projekt kojemu će pripadati stavka.';

    const [errorProjectCategoryID, setErrorProjectCategoryID] = useState(false);
    const errorProjectCategoryIDMessage = 'Izaberite kategoriju projekta.';

    const [errorAmount, setErrorAmount] = useState(false);
    const errorAmountMessage = 'Upišite iznos veći od nule..';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const entitySelectedID = bankStatementIDRef.current.value;
        const enteredName = nameInputRef.current.value;
        const enteredProjectIDInput = projectIDInputRef.current.value;
        const enteredProjectCategoryID = projectCategoryIDInputRef.current.value;
        const enteredAmount = amountInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(entitySelectedID)) {
            setErrorEnityID(true);
            globalError = true;
        }
        else {
            setErrorEnityID(false);
        }

        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        if (!validateEmptyInput(enteredProjectIDInput)) {
            setErrorProjectID(true);
            globalError = true;
        }
        else {
            setErrorProjectID(false);
        }

        if (!validateEmptyInput(enteredProjectCategoryID)) {
            setErrorProjectCategoryID(true);
            globalError = true;
        }
        else {
            setErrorProjectCategoryID(false);
        }

        if (!validateEmptyInput(enteredAmount) || parseFloat(enteredAmount) <= 0) {
            setErrorAmount(true);
            globalError = true;
        }
        else {
            setErrorAmount(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postAddBankStatementInputExpanseData({ enteredName, enteredProjectIDInput, enteredProjectCategoryID }, bankStatementID, entryID, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);

                itemsReset(v => v + 1)

                props.hide();
            }

        }

    }

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>
                <Modal.Title>Dodajte {entryData.income_expanse === 'E' ? 'odljev' : 'priljev'}</Modal.Title>
            </Modal.Header>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <Modal.Body>
                    <div>
                        <label>Platitelj</label>
                        <div>{entryData.party_identify}</div>
                    </div>
                    <div className="mb-3">
                        <label>Opis plaćanja</label>
                        <div>{entryData.description_payment}</div>
                    </div>
                    <Input
                        ref={bankStatementIDRef}
                        name="bankStatementID"
                        type="hidden"
                        label=""
                        autoComplete="off"
                        defaultValue={entryID}
                        error={errorEnityID ? errorEnityIDMessage : null}
                    />
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label={"Naziv " + (entryData.income_expanse === 'E' ? 'Odljeva' : 'Priljeva')}
                        autoComplete="off"
                        defaultValue={entryData.description_payment}
                        error={errorName ? errorNameMessage : null}
                    />
                    {clientProjects &&
                        <ProjectsSelect
                            ref={projectIDInputRef}
                            name="project_id"
                            label={(entryData.income_expanse === 'E' ? 'Odljev' : 'Priljev') + " pripada projektu"}
                            autoComplete="off"
                            options={clientProjects}
                            error={errorProjectID ? errorProjectIDMessage : null}
                            onChangeHandler={handleSelectProjectChange}
                        />
                    }
                    {projectCategories &&
                        <CategorySelect
                            ref={projectCategoryIDInputRef}
                            name="project_category_id"
                            label={(entryData.income_expanse === 'E' ? 'Odljev' : 'Priljev') + " pripada kategoriji"}
                            autoComplete="off"
                            options={projectCategories}
                            error={errorProjectCategoryID ? errorProjectCategoryIDMessage : null}
                        />
                    }
                    <Input
                        ref={amountInputRef}
                        name="amount"
                        type="number"
                        label="Iznos"
                        autoComplete="off"
                        defaultValue={new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(entryData.amount).replace(/,(?=.*\.\d+)/g, '')}
                        step="0.01"
                        disabled="disabled"
                        error={errorAmount ? errorAmountMessage : null}
                    />
                    <Input
                        ref={dateInputRef}
                        name="date"
                        type="date"
                        label="Datum troška"
                        autoComplete="off"
                        defaultValue={entryData.booking_date}
                        disabled="disabled"
                        error={null}
                    />


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Zatvori</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Dodaj priljev/odljev</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default BankStatementIncomeExpanseAddModal;