import React from 'react';
import { useRecoilValue } from "recoil";
import { NavLink } from "react-router-dom";
import { Dropdown, Navbar } from "react-bootstrap";

import classes from './Header.module.css';

import { currentUserProfileState, currentUserPermissionsState } from '../../store';
import Logo from "../UI/Logo";

const Header = () => {

    const userData = useRecoilValue(currentUserProfileState);
    const userPermissions = useRecoilValue(currentUserPermissionsState);

    return (
        <header className={`d-flex flex-wrap justify-content-md-between py-1 px-2 align-items-center ${classes.header}`}>
            <Navbar expand="lg" className="w-100">
                <div className="d-flex navbar-brand">
                    <Logo />{userData.id}
                </div>
                <Navbar.Toggle aria-controls="navbarCollapse" />
                <Navbar.Collapse id="navbarScroll" className="flex-grow-1">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0 flex-grow-1">
                        {userPermissions.includes('manage_basic_categories') &&
                            (
                                <li className="nav-item">
                                    <NavLink
                                        to="/basic-categories/all"
                                        className={({ isActive }) => (isActive ? 'nav-link px-2 py-0 active' : 'nav-link px-2 py-0 inactive')}
                                    >
                                        Osnovne kategorije
                                    </NavLink>
                                </li>
                            )}
                        {userPermissions.includes('manage_users') &&
                            (
                                <li className="nav-item">
                                    <NavLink
                                        to="/clients/all"
                                        className={({ isActive }) => (isActive ? 'nav-link px-2 py-0 active' : 'nav-link px-2 py-0 inactive')}
                                    >
                                        Klijenti
                                    </NavLink>
                                </li>
                            )}
                        {userPermissions.includes('manage_clients') &&
                            (
                                <li className="nav-item">
                                    <NavLink
                                        to="/manage-users/all"
                                        className={({ isActive }) => (isActive ? 'nav-link px-2 py-0 active' : 'nav-link px-2 py-0 inactive')}
                                    >
                                        Korisnici
                                    </NavLink>
                                </li>
                            )}
                    </ul>
                    <div className="d-flex">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-user" className={classes.dropdown} bsPrefix={classes.dropdown_toggle}>
                                {userData.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={classes.dropdown_menu}>
                                <Dropdown.Item href="/postavke">Postavke</Dropdown.Item>
                                <Dropdown.Item href="/logout">Odjava</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </header>

    );

};

export default Header;