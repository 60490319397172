import React, { useEffect } from "react";
import { selectorFamily, useRecoilState, atom } from "recoil";

const namesAtomState = atom({
    key: 'atomNames',
    default: []
});

const namesState = selectorFamily({
    key: 'names',
    get: (page) => ({ get }) => {

        let data = [
            { name: 'Tom' },
            { name: 'John' },
            { name: 'Jane' }
        ];

        return data;
    }
    ,
    set: () => ({ set, get }, value) => {

        const names = get(namesState(2));
        
        let newNames = [...names].map((data, i) => {
            let newData = { ...data };
            if (i === 1) {
                newData.name = 'Steve';
            }
            return newData;
        });

        set(namesAtomState, newNames);

    }
});


const ChangeName = () => {

    const [namesAtom, setNamesAtom] = useRecoilState(namesAtomState);
    const [names, setNames] = useRecoilState(namesState(2));

    // Update state when AJAX return values
    useEffect(()=>{
        if (names) {
            setNamesAtom(names);
        }
    },[names, setNamesAtom]);

    // Set new name for second value in array
    const changeSecondName = () => {
        setNames(2);
    };

    return (
        <div>
            <ul>
                {namesAtom.map((item) => {
                    return (
                        <li key={item.name}>{item.name}</li>
                    );
                })}
            </ul>
            <button onClick={changeSecondName}>Change second name to Steve</button>
        </div>
    );
};

export default ChangeName;