import React, { useState, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { tokenState, successState, projectCategoriesState } from "../../../store";
import { deleteCategoryData } from "../../../services";

import { removeCategoryFromArrayExcludeIncome } from "../../../helpers/categories";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../../elements/FormElement';
import Button from '../../../components/UI/Button';
import CategorySelect from "../Category/CategorySelect";

import classes from './CategoryModal.module.css';

const CategoryDeleteModal = (props) => {

    const id = props.item ? props.item.id : null
    const projectID = props.projectID;
    const clientID = props.clientID;

    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const projectCategories = useRecoilValue(projectCategoriesState);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const projectCategoryIDInputRef = useRef();

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const categoryReplaceID = projectCategoryIDInputRef.current.value;

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await deleteCategoryData(id, projectID, clientID, categoryReplaceID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);
                props.updateValue({item: response.item, type: 'DELETE'});
                props.hide();
            }

        }

    }

    const currentItem = props.item ? props.item : {};
    const parentItemID = props.item ? props.item.parent_id : 0;
    const categorySelectTitle = props.item && props.item.income_expanse === 'I' ? 'Priljevi će se prebaciti u kategoriju:' : 'Odljevi će se prebaciti u kategoriju';
    const projectCategoriesSelect = removeCategoryFromArrayExcludeIncome(projectCategories, currentItem);

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>Î
                <Modal.Title>Da li ste sigurni da želite izbrisati kategoriju: <em>{props.item ? props.item.name : null}</em></Modal.Title>
            </Modal.Header>
            <div>
                Ukoliko brišete glavnu kategoriju izbrisati će se sve podkategorije!
            </div>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <CategorySelect
                        ref={projectCategoryIDInputRef}
                        name="project_category_id"
                        label={categorySelectTitle}
                        autoComplete="off"
                        options={projectCategoriesSelect}
                        error={null}
                        defaultValue={parentItemID}
                    />
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Odustani</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Izbriši</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default CategoryDeleteModal;