import { fetchWrapperClass } from './fetchWrapper';
import { userProfileUrl, userUrl } from './urlServices';

const fetchWrapperUser = fetchWrapperClass();

export const getUserData = async (token) => {
    return await fetchWrapperUser.get(userProfileUrl, token).then(data => { return data; });
};

export const postNewUserData = async (postData, token) => {
        return await fetchWrapperUser.post(userUrl, token, postData).then(data => { return data; });
};

export const getManageUsersData = async (token, page) => {
    return await fetchWrapperUser.get(userUrl + '?page=' + page, token).then(data => { return data; });
};

export const getOneUserData = async (token, id) => {
    return await fetchWrapperUser.get(userUrl + '/' + id, token).then(data => { return data; });
};

export const postEditUserData = async (postData, id, token) => {
    return await fetchWrapperUser.put(userUrl + '/' + id, token, postData).then(data => { return data; });
};

export const deleteUserData = async (id, token) => {
    return await fetchWrapperUser.delete(userUrl + '/' + id, token).then(data => { return data; });
};