import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './ResetPasswordForm.module.css'

import Input from '../UI/Input';
import Button from '../UI/Button';

import { validateEmail, validateEmptyInput } from '../../helpers/inputValidate';
import { doUserTokenResetCheck, doUserNewPassword } from '../../services';

const ResetPasswordForm = (props) => {

    /* State variables */
    let navigate = useNavigate();
    const [tokenExpireMsg, setTokenExpireMsg] = useState(null);
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);

    const tokenQuery = params.get('token');
    const emailQuery = params.get('email');

    // Check if we have token and email in query params
    useEffect(() => {
        if (!tokenQuery || !emailQuery || tokenQuery.trim() === '' || emailQuery.trim() === '') {
            navigate('/login');
        }
    }, [tokenQuery, emailQuery, navigate]);

    // Check if token is expired
    useEffect(() => {
        const checkTokenExpire = async (tokenQuery) => {
            const response = await doUserTokenResetCheck(tokenQuery, emailQuery);

            if (response && response.success === false) {
                setTokenExpireMsg(response.message);
            }
        };

        checkTokenExpire(tokenQuery);
    }, [tokenQuery, emailQuery]);


    /* Form login part with validation */
    let globalError = false;
    const tokenInputRef = useRef();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const passwordRepeatInputRef = useRef();

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorPasswordMessage, setErrorPasswordMessage] = useState('Upišite ispravnu lozinku.');

    const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);
    const errorPasswordRepeatMessage = 'Upišite ispravnu lozinku.';

    const [errorPasswordDifferent, setErrorPasswordDifferent] = useState(false);
    const errorPasswordDifferentMessage = 'Lozinku i ponovljena lozinka nisu iste.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredToken = tokenInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredPasswordRepeat = passwordRepeatInputRef.current.value;

        // Validate email and token and password
        if (!validateEmptyInput(enteredToken)) {
            globalError = true;
        }

        if (!validateEmail(enteredEmail)) {
            globalError = true;
        }

        if (!validateEmptyInput(enteredPassword)) {
            setErrorPasswordMessage('Upišite ispravnu lozinku.');
            setErrorPassword(true);
            globalError = true;
        }
        else if(enteredPassword.length < 6){
            setErrorPasswordMessage('Upišite lozinku od najmanje 6 znakova.');
            setErrorPassword(true);
            globalError = true;
        }
        else {
            setErrorPassword(false);
        }

        if (!validateEmptyInput(enteredPasswordRepeat)) {
            setErrorPasswordRepeat(true);
            globalError = true;
        }
        else {
            setErrorPasswordRepeat(false);
        }

        if (validateEmptyInput(enteredPassword) && validateEmptyInput(enteredPasswordRepeat) && enteredPassword !== enteredPasswordRepeat) {
            setErrorPasswordDifferent(true);
            globalError = true;
        } else {
            setErrorPasswordDifferent(false);
        }

        // Log user if everything ok
        if (!globalError) {

            console.log('Everything ok, log user in');

            const response = await doUserNewPassword(enteredToken, enteredEmail, enteredPassword);

            if (response && response.success === true) {
                setTokenExpireMsg(response.message);
            }
            else{
                setTokenExpireMsg(response.message);
            }


            // ???fetchWrapperAuth.post(loginUrl, null, loginData).then(data => { return data; });

            // const response = await doUserResetPassword(enteredEmail);

            // console.log(response);

            // if (!response) {
            //     setErrorEmail(true);
            //     setErrorPassword(true);
            // }
            // else { // Log in user if no errors, save all neccessary information

            //     setToken(response.token);
            //     setUserData(response.data);

            //     saveSessionLocalStorage(response);

            //     navigate('/');
            // }

        }

    }

    return (
        <div className={`d-flex flex-grow-1 h-100 justify-content-center ${classes.login_right}`}>
            <div className="d-flex flex-column justify-content-center">
                <form className="form" onSubmit={handleFormSubmit}>
                    <div className={`${classes.form_header} form-header mb-5 text-center`}>
                        <h2 className="mb-3">Utipkajte novu lozinku</h2>
                        <div>
                            Nemate pristup? <a href="mailto:poduzetnicki.centar@synergia-consulting.hr" className="link-blue">Kontaktirajte korisničku podršku</a>
                        </div>
                    </div>
                    {!tokenExpireMsg &&
                        <>
                            <Input
                                ref={tokenInputRef}
                                name="token"
                                type="hidden"
                                autoComplete="off"
                                defaultValue={tokenQuery}
                            />
                            <Input
                                ref={emailInputRef}
                                name="email"
                                type="hidden"
                                autoComplete="off"
                                defaultValue={emailQuery}
                            />
                            <Input
                                ref={passwordInputRef}
                                name="password"
                                type="password"
                                label="Lozinka"
                                autoComplete="off"
                                error={errorPassword ? errorPasswordMessage : null}
                            />
                            <Input
                                ref={passwordRepeatInputRef}
                                name="password-repeat"
                                type="password"
                                label="Ponovite lozinku"
                                autoComplete="off"
                                error={errorPasswordRepeat ? errorPasswordRepeatMessage : errorPasswordDifferent ? errorPasswordDifferentMessage : null}
                            />
                            <div className="text-center">
                                <Button
                                    name="submit"
                                    type="submit"
                                >
                                    Resetiraj lozinku
                                </Button>
                            </div>
                        </>}
                        {tokenExpireMsg &&
                        <>
                            <div className={classes.responseDiv}>{tokenExpireMsg}</div>
                            <div className='text-center'>
                                <Button
                                    onClick={() => navigate('/')}
                                >
                                    Povratak na prijavu
                                </Button>
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;