import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { currentUserPermissionsState, tokenState, successState, basicCategoriesState, basicCategoryEditState, incomeExpanseState } from "../../../store";
import { postEditBasicCategoryData } from "../../../services";

import BasicCategoryHeader from './BasicCategoryHeader';
import FormElement from "../../../components/elements/FormElement";

import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';

export const EditBasicCategory = () => {

    const { id } = useParams()

    const itemEditData = useRecoilValue(basicCategoryEditState(id));
    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const token = useRecoilValue(tokenState);
    const incomeExpanse = useRecoilValue(incomeExpanseState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();

    // Reset states
    const resetItemsPage = useRecoilRefresher_UNSTABLE(basicCategoriesState);
    const resetItem = useRecoilRefresher_UNSTABLE(basicCategoryEditState(id));

    // Check user permission
    useEffect(() => {
        if (!userPermissions.includes('manage_basic_categories')) { navigate('/'); }
    }, [userPermissions, navigate]);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const orderingInputRef = useRef();
    const incomeExpanseInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv osnovne kategorije.';

    const [errorOrdering, setErrorOrdering] = useState(false);
    const errorOrderingMessage = 'Upišite ispravni redoslijed kategorije.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredOrdering = orderingInputRef.current.value;
        const enteredIncomeExpanse = incomeExpanseInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }
        if (!validateEmptyInput(enteredOrdering)) {
            setErrorOrdering(true);
            globalError = true;
        }
        else {
            setErrorOrdering(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postEditBasicCategoryData({ enteredName, enteredOrdering, enteredIncomeExpanse }, id, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                resetItemsPage();
                resetItem();

                setSuccess(response.message);

                navigate('/basic-categories/all');
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <BasicCategoryHeader>
                <span className="d-inline-block ps-1">• Nova osnovna kategorija</span>
            </BasicCategoryHeader>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Izmjeni osnovnu kategoriju" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Osnovna kategorija"
                        autoComplete="off"
                        defaultValue={itemEditData.name}
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={orderingInputRef}
                        name="ordering"
                        type="number"
                        label="Redoslijed"
                        autoComplete="off"
                        defaultValue={itemEditData.ordering}
                        error={errorOrdering ? errorOrderingMessage : null}
                        minLength="1"
                    />
                    <Select
                        ref={incomeExpanseInputRef}
                        name="income_expanse"
                        label="Kategorija pripada priljevima ili odljevima?"
                        autoComplete="off"
                        options={incomeExpanse}
                        defaultValue={itemEditData.income_expanse}
                        error={null}
                    />
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Odustani</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Sačuvaj izmjene</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};