import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import Table from 'react-bootstrap/Table';

import { currentUserPermissionsState, basicCategoriesState, incomeExpanseState } from "../../../store";

import BasicCategoryHeader from './BasicCategoryHeader';
import ModalConfirm from "../../../components/UI/ModalConfirm";

export const BasicCategories = () => {

    const items = useRecoilValue(basicCategoriesState);
    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const incomeExpanse = useRecoilValue(incomeExpanseState);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [modalNavigate, setModalNavigate] = useState('/basic-categories/all');

    // Check user permission
    useEffect(() => {
        if (!userPermissions.includes('manage_basic_categories')) { navigate('/'); }
    }, [userPermissions, navigate]);

    const handleModalWindow = (title, text, navigate) => {
        setShowModal(true);
        setModalTitle(title);
        setModalText(text);
        setModalNavigate(navigate);
    }

    const handleModalHide = () => {
        setShowModal(false);
    }

    return (
        <div className="flex-grow-1">
            <BasicCategoryHeader />
            <div className="content">
                <Table className="table-list">
                    <thead>
                        <tr>
                            <th scope="col">Osnovna kategorija</th>
                            <th scope="col" className="text-center">Priljev / Odljev</th>
                            <th scope="col" className="text-center">Redoslijed</th>
                            <th scope="col">Opcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.data &&
                            items.data.map((item) => {

                                const incomeExpanseText = incomeExpanse.find(value => { 
                                    return value[0] === item.income_expanse ? true : false;
                                });

                                return (
                                    <tr key={item.slug}>
                                        <td><Link to={`/basic-categories/edit/${item.slug}`}>{item.name}</Link></td>
                                        <td className="text-center">{incomeExpanseText[1]}</td>
                                        <td className="text-center">{item.ordering}</td>
                                        <td>
                                            <Link to={`/basic-categories/edit/${item.slug}`} className="btn btn-blue-nbg">Izmjeni</Link>
                                            <button
                                                onClick={() => {
                                                    handleModalWindow('Da li ste sigurni da želite izbrisati osnovnu kategoriju?', item.name, '/basic-categories/delete/' + item.slug);
                                                }
                                                }
                                                className="ms-3 btn btn-red-nbg"
                                            >Izbriši</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {items && items.data.length === 0 &&
                            <tr>
                                <td colSpan="2">Nemate unesenih osnovnih kategorija</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
            <ModalConfirm show={showModal} title={modalTitle} text={modalText} hide={handleModalHide} navigate={modalNavigate} />
        </div>
    );
};