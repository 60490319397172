import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import Table from 'react-bootstrap/Table';

import { bankStatementsState } from "../../store";

import BankStatementHeader from './BankStatementHeader';
import PaginationList from '../../components/elements/PaginationList';
import ModalConfirm from "../../components/UI/ModalConfirm";

export const BankStatements = () => {

    const { clientID, page } = useParams();

    const currentPage = (page) ? parseInt(page) : 1;
    const items = useRecoilValue(bankStatementsState({clientID, currentPage}));

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [modalNavigate, setModalNavigate] = useState(`/bank-statements/all/${clientID}`);

    const handleModalWindow = (title, text, navigate) => {
        setShowModal(true);
        setModalTitle(title);
        setModalText(text);
        setModalNavigate(navigate);
    }

    const handleModalHide = () => {
        setShowModal(false);
    }

    return (
        <div className="flex-grow-1">
            <BankStatementHeader />
            <div className="content">
                <Table className="table-list">
                    <thead>
                        <tr>
                            <th scope="col">Id. broj</th>
                            <th scope="col">BANKA</th>
                            <th scope="col">Redni broj</th>
                            <th scope="col">Datum izvatka</th>
                            <th scope="col">Datum dodavanja</th>
                            <th scope="col">Opcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.data &&
                            items.data.map((item) => {

                                const date_item_created = new Date(item.creation_datetime);
                                const date_item_uploaded = new Date(item.updated_at);
                                
                                

                                return (
                                    <tr key={item.id}>
                                        <td>{item.legal_sequence_number}</td>
                                        <td><Link to={`/bank-statements/edit/${item.id}/${clientID}/${currentPage}`}>{item.bank_name}</Link></td>
                                        <td>{item.message_identification}</td>
                                        <td>{String(date_item_created.getDate()+'.'+(date_item_created.getMonth()+1)+'.'+date_item_created.getFullYear())}</td>
                                        <td>{String(date_item_uploaded.getDate()+'.'+(date_item_uploaded.getMonth()+1)+'.'+date_item_uploaded.getFullYear())}</td>
                                        <td>
                                            <Link to={`/bank-statements/edit/${item.id}/${clientID}/${currentPage}`} className="btn btn-blue-nbg">Stavke izvatka</Link>
                                            <button
                                                onClick={() => {
                                                    handleModalWindow('Da li ste sigurni da želite izbrisati bankovni izvadak?', `${item.bank_name}: ${item.legal_sequence_number}`, '/bank-statements/delete/' + item.id + '/' + clientID + '/' + currentPage);
                                                }}
                                                className="ms-3 btn btn-red-nbg"
                                            >Izbriši</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {items && items.data.length === 0 &&
                            <tr>
                                <td colSpan="7">Nemate unesenih bankovih izvadaka.</td>
                            </tr>
                        }
                    </tbody>
                </Table>
                {items && items.data.length > 0 &&
                    <>
                        <PaginationList
                            linkHref={`/bank-statements/all/${clientID}`}
                            currentPage={currentPage}
                            perPage={items.perPage}
                            numberOfPages={items.last_page}
                            total={items.total}
                        />
                    </>
                }
            </div>
            <ModalConfirm show={showModal} title={modalTitle} text={modalText} hide={handleModalHide} navigate={modalNavigate} />
        </div>
    );
};