import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBottom from "../../../components/Layout/HeaderBottom";

const ClientsHeader = ({ children, page }) => {

    return (
        <HeaderBottom>
            <div className="headerLeft">
                <h2 className="d-inline-block">
                    <Link to={`/clients/all/`}>Klijenti</Link>
                </h2>
                {children}
            </div>
            <div className="headerRight">
                <Link to={`/clients/new/${page}`} className="btn btn-light-blue">Dodaj novog klijenta</Link>
            </div>
        </HeaderBottom>
    );
};

export default ClientsHeader;