import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { projectColumnsState, projectCurrentYearState, projectYearsState } from '../../../store';

import ProjectTableIncome from './ProjectTableIncome';
import ProjectTableExpense from './ProjectTableExpense';
import ProjectTableTotal from './ProjectTableTotal';

import classes from './ProjectTable.module.css';

const ProjectTable = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const columns = useRecoilValue(projectColumnsState);
    const [currentYear, setCurrentYear] = useRecoilState(projectCurrentYearState);
    const years = useRecoilValue(projectYearsState({projectID, clientID}));

    const changeCurrentYear = (year) => {
        setCurrentYear(year);
    };

    // Check if current year excided project years
    useEffect(() =>{

        if(currentYear > years[years.length - 1].year_number){
            setCurrentYear(years[years.length - 1].year_number);
        }

    }, [years, currentYear, setCurrentYear]);

    return (
        <div className={classes.projectTable}>
            <div className={classes.years}>
                {years && years.length > 0 && years.map((year, m) =>{
                    const active = (year.year_number === currentYear)? classes.active : null;
                    return <button key={m} onClick={() => changeCurrentYear(year.year_number)} className={active}>{year.year_number}</button>;
                })}
            </div>
            <table className="table" style={{ width: '100%' }}>
                {columns && (
                    <thead>
                        <tr>
                            {Object.keys(columns).map((key, i) => {
                                return <th key={i} scope="col">{columns[key].title}</th>
                            })}
                        </tr>
                    </thead>
                )}
                <tbody>
                    <ProjectTableIncome projectID={projectID} clientID={clientID} />
                    <ProjectTableExpense projectID={projectID} clientID={clientID} />
                    {columns && (
                        <tr>
                            {Object.keys(columns).map((key, i) => {
                                return <th key={i} scope="col">{columns[key].title}</th>
                            })}
                        </tr>
                    )}
                    <ProjectTableTotal projectID={projectID} clientID={clientID} />
                </tbody>
            </table>
        </div>
    );
}

export default ProjectTable;