import React from 'react';

import classes from './CategorySelect.module.css'

const CategorySelect = React.forwardRef((props, ref) => {

    const inputClasses = props.styles ? props.styles + classes.input_over : classes.input_over;

    return (
        <div className={inputClasses}>
            <label htmlFor={props.name} className={`form-label ${classes.form_label}`}>
                {props.label}
                {props.additionalLabel &&
                    <div className={classes.additionalLabel}>{props.additionalLabel}</div>
                }
            </label>
            <select 
                ref={ref}
                type={props.type}
                className={`form-control ${classes.form_control}`}
                id={props.name}
                aria-describedby={`${props.name}Help`}
                defaultValue={props.defaultValue}
            >
                {props.options.map((option, i) => {
                    return (
                        <React.Fragment key={i}>
                            <option key={option.id.toString()+i.toString()} value={option.id}>{option.name}</option>
                            {option.childs && option.childs.length > 0 && (
                                <React.Fragment key={option.id}>
                                    {option.childs.map((child1, i1) => {
                                        return (
                                            <React.Fragment key={i1}>
                                                <option key={child1.id} value={child1.id}>- {child1.name}</option>
                                                {child1.childs && child1.childs.map((child2, i2) => {
                                                    return <option key={child2.id} value={child2.id}>-- {child2.name}</option>;
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                })}
            </select>
            {props.help &&
                <div id={`${props.name}Help`} className="form-text">{props.help}</div>
            }
            {props.error &&
                <div id={`${props.name}Error`} className={`form-text ${classes.form_error_text}`}>{props.error}</div>
            }
        </div>
    );
});

export default CategorySelect;