import CryptoJS from "crypto-js";

export const encryptWithAES = (text) => {
    const passphrase = "88f9dsaifj8(())(uijfoidsajfsdojfoidsja";
    return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext) => {
    const passphrase = "88f9dsaifj8(())(uijfoidsajfsdojfoidsja";
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};