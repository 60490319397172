import { encryptWithAES, decryptWithAES } from '../helpers/encrypt'
import { loginUrl, logoutUrl, resetPaswordUrl, resetPasswordTokenCheckUrl, resetPasswordNewUrl } from './urlServices';
import { fetchWrapperClass } from './fetchWrapper';

const localStorageSessionName = 'sessionUserData';

const fetchWrapperAuth = fetchWrapperClass();

export const saveSessionLocalStorage = (data) => {
    return localStorage.setItem(localStorageSessionName, encryptWithAES(JSON.stringify(data)));
};

export const getSessionLocalStorage = () => {
    const storageDate = localStorage.getItem(localStorageSessionName);
    if (storageDate) {
        return JSON.parse(decryptWithAES(localStorage.getItem(localStorageSessionName)));
    }
    return null;
};

export const removeSessionLocalStorage = () => {
    return localStorage.removeItem(localStorageSessionName);
};

export const doUserLogin = async (email, password) => {

    const loginData = {
        'email': email,
        'password': password
    };

    return await fetchWrapperAuth.post(loginUrl, null, loginData).then(data => { return data; });
};

export const doUserLogout = async (token) => {
    return await fetchWrapperAuth.get(logoutUrl, token).then(data => { return data; });
};

export const doUserResetPassword = async (email) => {
    return await fetchWrapperAuth.post(resetPaswordUrl, null, {email: email}).then(data => { return data; });
};

export const doUserTokenResetCheck = async (tokenQuery, emailQuery) => {
    return await fetchWrapperAuth.get(resetPasswordTokenCheckUrl + '/' + tokenQuery + '/' + emailQuery, null).then(data => { return data; });
};

export const doUserNewPassword = async (enteredToken, enteredEmail, enteredPassword) => {
    return await fetchWrapperAuth.post(resetPasswordNewUrl, null, {token: enteredToken, email: enteredEmail, password: enteredPassword}).then(data => { return data; });
};