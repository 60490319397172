import React from 'react';
import classes from './HeaderBottom.module.css';

const HeaderBottom = ({children}) => {
    return (
        <div className={classes.headerBottom}>
            {children}
        </div>
    );
};

export default HeaderBottom;