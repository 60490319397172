import React, { useRef, useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useResetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

import { projectCategoriesState } from '../../../store';

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { tokenState, successState, projectCurrentYearState, projectYearTotalsState, incomeExpanseEditState, projectCategoriesIncomeOrExpanseState } from "../../../store";
import { postEditInputExpanseData } from "../../../services";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../../elements/FormElement';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import CategorySelect from "../Category/CategorySelect";

import classes from './IncomeExpanseModal.module.css';

const IncomeExpanseEditModal = (props) => {

    const incomeExpanseID = props.incomeExpanse ? props.incomeExpanse.id : null;
    const projectID = props.projectID;
    const clientID = props.clientID;

    const [incomeExpanseMark, setIncomeExpanseMark] = useState(null);

    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const incomeExpanseEditData = useRecoilValue(incomeExpanseEditState({ projectID, clientID, incomeExpanseID }));
    const resetIncomeExpanse = useRecoilRefresher_UNSTABLE(incomeExpanseEditState({ projectID, clientID, incomeExpanseID }));
    const currentYear = useRecoilValue(projectCurrentYearState);
    const yearTotalsReset = useResetRecoilState(projectYearTotalsState({ projectID, clientID, currentYear }));
    const projectCategories = useRecoilValue(projectCategoriesState);
    const projectCategoriesBankStatement = useRecoilValue(projectCategoriesIncomeOrExpanseState({ selectedProjectID: projectID, clientID, incomeExpanseMark }));

    console.log(projectCategoriesBankStatement);

    useEffect(() => {
        if (incomeExpanseEditData && incomeExpanseEditData.party_identify) {
            setIncomeExpanseMark(incomeExpanseEditData.income_expanse);
        }
    }, [incomeExpanseEditData]);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const projectCategoryIDInputRef = useRef();
    const amountInputRef = useRef();
    const dateInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv osnovne kategorije.';

    const [errorAmount, setErrorAmount] = useState(false);
    const errorAmountMessage = 'Upišite iznos veći od nule..';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredProjectCategoryID = projectCategoryIDInputRef.current.value;
        const enteredAmount = amountInputRef.current.value;
        const enteredDate = dateInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        if (!validateEmptyInput(enteredAmount) || parseFloat(enteredAmount) <= 0) {
            setErrorAmount(true);
            globalError = true;
        }
        else {
            setErrorAmount(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postEditInputExpanseData({ enteredName, enteredProjectCategoryID, enteredAmount, enteredDate }, projectID, clientID, token, incomeExpanseID);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);

                yearTotalsReset(v => v + 1);

                resetIncomeExpanse();

                props.refreshSearch();
                props.hide();
                props.added();
            }

        }

    }

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>
                <Modal.Title>Dodajte priljev/odljev</Modal.Title>
            </Modal.Header>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <Modal.Body>
                    {incomeExpanseEditData.party_identify &&
                        <>
                            <div>
                                <label>Platitelj</label>
                                <div>{incomeExpanseEditData.party_identify}</div>
                            </div>
                            <div className="mb-3">
                                <label>Opis plaćanja</label>
                                <div>{incomeExpanseEditData.description_payment}</div>
                            </div>
                        </>
                    }
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Naziv Priljeva / Odljeva"
                        autoComplete="off"
                        defaultValue={incomeExpanseEditData.name}
                        error={errorName ? errorNameMessage : null}
                    />
                    {incomeExpanseEditData.party_identify && projectCategoriesBankStatement &&
                        <CategorySelect
                            ref={projectCategoryIDInputRef}
                            name="project_category_id"
                            label="Priljev / Odljev pripada kategoriji"
                            autoComplete="off"
                            options={projectCategoriesBankStatement}
                            defaultValue={incomeExpanseEditData.project_category_id}
                            error={null}
                        />
                    }
                    {!incomeExpanseEditData.party_identify && 
                        <CategorySelect
                            ref={projectCategoryIDInputRef}
                            name="project_category_id"
                            label="Priljev / Odljev pripada kategoriji"
                            autoComplete="off"
                            options={projectCategories}
                            defaultValue={incomeExpanseEditData.project_category_id}
                            error={null}
                        />
                    }
                    <Input
                        ref={amountInputRef}
                        name="amount"
                        type="number"
                        label="Iznos"
                        autoComplete="off"
                        defaultValue={new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(incomeExpanseEditData.amount).replace(/,(?=.*\.\d+)/g, '')}
                        step="0.01"
                        disabled={incomeExpanseEditData.party_identify ? true : false}
                        error={errorAmount ? errorAmountMessage : null}
                    />
                    <Input
                        ref={dateInputRef}
                        name="date"
                        type="date"
                        label="Datum troška"
                        autoComplete="off"
                        defaultValue={incomeExpanseEditData.date_item_created}
                        disabled={incomeExpanseEditData.party_identify ? true : false}
                        error={null}
                    />


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Zatvori</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Izmjeni priljev/odljev</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default IncomeExpanseEditModal;