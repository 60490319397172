import { atom } from "recoil";

import { getSessionLocalStorage } from "../services";

export const isLoadingState = atom({
    key: 'isLoading',
    default: false
});

export const userState = atom({
    key: 'user',
    default: {
        name: null,
    }
});

export const tokenState = atom({
    key: 'token',
    default: getSessionLocalStorage() ? getSessionLocalStorage().token : null,
    //default: null,
});

// export const logoutState = selector({
//     key: 'logout',
//     get: () => {
//         return null;
//     },
//     set: ({set, get}) => {

//         // current token
//         const currentToken = get(tokenState);

//         // Set token to null
//         set(tokenState, null);

//         // Set local storage state
//         localStorage.removeItem('sessionToken');

//         // Send feedback to backend
//         return doUserLogout(currentToken);
//     }
// });

// export const tokenSelector = selector({
//     key: 'tokenManage',
//     get: (davidmo) => {
//         const tokenLocal = localStorage.getItem('sessionToken');
//         return tokenLocal;
//     },
//     set: ({set}, newValue) => {
//         localStorage.setItem('sessionToken', newValue)
//         set(tokenState, localStorage.getItem('sessionToken'));
//     }
// });

// const isLoadingState = selectorFamily({
//     key: 'isLoading',  
//     get: () => () => {
//         return false;  
//     },
//     set: () => ({set}, newValue) => {
//         set();
//     },
// });
// optional set  set: (multiplier) => ({set}, newValue) => {    set(myNumberState, newValue / multiplier);  },});