export const removeCategoryFromArrayExcludeIncome = (categories, item) => {

    let newProjectCategories = [...categories];

    if (item.parent_id === null) {
        newProjectCategories =  newProjectCategories.filter(element => element.id !== item.id && element.income_expanse === item.income_expanse);
    }
    else {
        newProjectCategories = newProjectCategories.map((data, i) => {
            let newData = { ...data };

            if(newData.income_expanse === item.income_expanse){
                if (data.id === parseInt(item.parent_id)) {
                    newData.childs = newData.childs.filter(element => element.id !== item.id && element.income_expanse === item.income_expanse);
                }
                else {
                    let newProjectCategories1 = newData.childs.map((data1, i1) => {
                        let newData1 = { ...data1 };
                        if (data1.id === parseInt(item.parent_id)) {
                            newData1.childs = newData1.childs.filter(element => element.id !== item.id && element.income_expanse === item.income_expanse);
                        }
                        return newData1;
                    });
    
                    newData.childs = newProjectCategories1;
                }
    
                return newData;
            }
            return null;

        });
    }

    newProjectCategories = newProjectCategories.filter(element => element != null);

    return newProjectCategories;
   
};