import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { currentUserPermissionsState, tokenState, successState, clientsEditState, clientsState, legalFormClientState } from "../../../store";
import { postEditClientData } from "../../../services";

import ClientsHeader from './ClientsHeader';
import FormElement from "../../../components/elements/FormElement";

import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button';
import Select from '../../../components/UI/Select';

export const EditClient = () => {

    const { id, page } = useParams()
    const itemEditData = useRecoilValue(clientsEditState(id));
    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const legalFormClient = useRecoilValue(legalFormClientState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);

    // Reset states
    const resetItemsPage = useRecoilRefresher_UNSTABLE(clientsState(parseInt(page)));
    const resetItem = useRecoilRefresher_UNSTABLE(clientsEditState(id));

    const navigate = useNavigate();

    // Check user permission
    useEffect(() => {
        if (!userPermissions.includes('manage_clients')) { navigate('/'); }
    }, [userPermissions, navigate]);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const OIBInputRef = useRef();
    const addressInputRef = useRef();
    const legalFormInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv klijenta.';

    const [errorOIB, setErrorOIB] = useState(false);
    const errorOIBMessage = 'Upišite ispravni OIB klijenta.';

    const [errorAddress, setErrorAddress] = useState(false);
    const errorAddressMessage = 'Upišite ispravnu adresu klijenta.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredOIB = OIBInputRef.current.value;
        const enteredAddress = addressInputRef.current.value;
        const enteredLegalForm = legalFormInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }
        if (!validateEmptyInput(enteredOIB)) {
            setErrorOIB(true);
            globalError = true;
        }
        else if (validateEmptyInput(enteredOIB) && (enteredOIB.length < 11 || enteredOIB.length > 11)) {
            setErrorOIB(true);
            globalError = true;
        }
        else {
            setErrorOIB(false);
        }
        if (!validateEmptyInput(enteredAddress)) {
            setErrorAddress(true);
            globalError = true;
        }
        else {
            setErrorAddress(false);
        }

        // Save edit data
        if (
            !globalError
        ) {

            const response = await postEditClientData({ enteredName, enteredOIB, enteredAddress, enteredLegalForm }, id, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                resetItem();
                resetItemsPage();

                setSuccess(response.message);

                navigate('/clients/all/' + page);
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <ClientsHeader>
                <span className="d-inline-block ps-1">• Izmjena klijenta • <em>{itemEditData.name}</em></span>
            </ClientsHeader>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Izmjeni klijenta" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Klijent"
                        autoComplete="off"
                        defaultValue={itemEditData.name}
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={OIBInputRef}
                        name="oib"
                        type="text"
                        label="OIB"
                        autoComplete="off"
                        defaultValue={itemEditData.oib}
                        error={errorOIB ? errorOIBMessage : null}
                        minLength="11"
                        maxLength="11"
                    />
                    <Input
                        ref={addressInputRef}
                        name="address"
                        type="text"
                        label="Adresa"
                        autoComplete="off"
                        defaultValue={itemEditData.address}
                        error={errorAddress ? errorAddressMessage : null}
                    />
                    <Select
                        ref={legalFormInputRef}
                        name="legal-form"
                        label="Pravni oblik"
                        autoComplete="off"
                        options={legalFormClient}
                        defaultValue={itemEditData.legal_form}
                        error={null}
                    />
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Cancel</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Sačuvaj izmjene</Button>
                    </div>
                </FormElement>
            </div>
        </div>
    );
};