import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from "recoil";

import { validateEmail, validateEmptyInput } from '../../../helpers/inputValidate';
import { currentUserPermissionsState, tokenState, successState, userTypesState, allClientsState } from "../../../store";
import { postNewUserData } from "../../../services";

import UsersHeader from './UsersHeader';
import FormElement from "../../../components/elements/FormElement";

import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';

export const AddNewUser = () => {

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const userTypes = useRecoilValue(userTypesState);
    const allClient = useRecoilValue(allClientsState);
    const token = useRecoilValue(tokenState);
    const setSuccess = useSetRecoilState(successState);
    const navigate = useNavigate();


    // Check user permission
    useEffect(() => {

        if (!userPermissions.includes('manage_users')) {
            navigate('/');
        }

    }, [userPermissions, navigate]);



    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const userTypeInputRef = useRef();
    const clientInputRef = useRef();
    const passwordInputRef = useRef();
    const passwordRepeatInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravno ime i prezime korisnika.';

    const [errorEmail, setErrorEmail] = useState(false);
    const errorEmailMessage = 'Upišite ispravnu email adresu.';

    const [errorPassword, setErrorPassword] = useState(false);
    const errorPasswordMessage = 'Upišite ispravnu lozinku.';

    const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);
    const errorPasswordRepeatMessage = 'Upišite ispravnu lozinku.';

    const [errorPasswordDifferent, setErrorPasswordDifferent] = useState(false);
    const errorPasswordDifferentMessage = 'Lozinku i ponovljena lozinka nisu iste.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredUserType = userTypeInputRef.current.value;
        const enteredClient = clientInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredPasswordRepeat = passwordRepeatInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }

        if (!validateEmail(enteredEmail)) {
            setErrorEmail(true);
            globalError = true;
        }
        else {
            setErrorEmail(false);
        }

        if (!validateEmptyInput(enteredPassword)) {
            setErrorPassword(true);
            globalError = true;
        }
        else {
            setErrorPassword(false);
        }

        if (!validateEmptyInput(enteredPasswordRepeat)) {
            setErrorPasswordRepeat(true);
            globalError = true;
        }
        else {
            setErrorPasswordRepeat(false);
        }

        if (validateEmptyInput(enteredPassword) && validateEmptyInput(enteredPasswordRepeat) && enteredPassword !== enteredPasswordRepeat) {
            setErrorPasswordDifferent(true);
            globalError = true;
        } else {
            setErrorPasswordDifferent(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postNewUserData({ enteredName, enteredEmail, enteredPassword, enteredUserType, enteredClient }, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);

                navigate('/manage-users/all');
            }

        }

    }

    return (
        <div className="flex-grow-1">
            <UsersHeader />
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FormElement onSubmitHandler={handleFormSubmit} title="Dodaj novog korisnika" status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Ime i prezime"
                        autoComplete="off"
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={emailInputRef}
                        name="email"
                        type="email"
                        label="Email"
                        autoComplete="off"
                        error={errorEmail ? errorEmailMessage : null}
                    />
                    <Select
                        ref={userTypeInputRef}
                        name="user-type"
                        label="Vrsta korisnika"
                        autoComplete="off"
                        options={userTypes}
                        error={null}
                    />
                    <Select
                        ref={clientInputRef}
                        name="client"
                        label="Pripada klijentu"
                        autoComplete="off"
                        options={allClient}
                        error={null}
                    />
                    <Input
                        ref={passwordInputRef}
                        name="password"
                        type="password"
                        label="Lozinka"
                        autoComplete="off"
                        error={errorPassword ? errorPasswordMessage : null}
                    />
                    <Input
                        ref={passwordRepeatInputRef}
                        name="password-repeat"
                        type="password"
                        label="Ponovite lozinku"
                        autoComplete="off"
                        error={errorPasswordRepeat ? errorPasswordRepeatMessage : errorPasswordDifferent ? errorPasswordDifferentMessage : null}
                    />
                    <div className="text-center pt-4">
                        <Button type="button" onClick={() => navigate(-1)} styles="btn btn-blue">Cancel</Button>
                        <Button type="submit" styles="btn btn-light-blue ms-3">Dodaj novog korisnika</Button>
                    </div>
                </FormElement>
            </div>

        </div>
    );
};