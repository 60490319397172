import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { projectCategoriesState, projectCategoriesFetchState } from '../../../store';

import CategoryAddModal from './CategoryAddModal';
import CategoryEditModal from './CategoryEditModal';
import CategoryDeleteModal from './CategoryDeleteModal';

import classes from './CategoryList.module.css'

const CategoryList = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    // Edit category modal state
    const [showCategoryEditModal, setShowCategoryEditModal] = useState(false);
    const [categoryModalEditData, setCategoryModalEditData] = useState(null);

    // Add category modal state
    const [showCategoryAddModal, setShowCategoryAddModal] = useState(false);
    const [categoryModalAddData, setCategoryModalAddData] = useState(null);

    // Delete category modal state
    const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
    const [categoryModalDeleteData, setCategoryModalDeleteData] = useState(null);

    const [projectCategories, setProjectCategories] = useRecoilState(projectCategoriesState);
    const [projectCategoriesFetch, setProjectCategoriesFetch] = useRecoilState(projectCategoriesFetchState({ projectID, clientID }));

    // Update state when AJAX return values
    useEffect(() => {
        if (projectCategoriesFetch) {
            setProjectCategories(projectCategoriesFetch);
        }
    }, [projectCategoriesFetch, setProjectCategories]);

    // Edit category modal functions
    const handleModalCategoryEdit = (category) => {
        setShowCategoryEditModal(true);
        setCategoryModalEditData(category);
    };

    const handleCategoryEditModalHide = () => {
        setShowCategoryEditModal(false);
    };

    const handleProjectCategoriesUpdate = (category) => {
        setProjectCategoriesFetch(category);
    }

    // Add category modal functions
    const handleModalCategoryAdd = (category) => {
        setShowCategoryAddModal(true);
        setCategoryModalAddData(category);
    };

    const handleCategoryAddModalHide = () => {
        setShowCategoryAddModal(false);
    };

    const handleProjectCategoriesAdded = (category) => {
        setProjectCategoriesFetch(category);
    }

    // Delete modal function
    const handleModalCategoryDelete = (category) => {
        setShowCategoryDeleteModal(true);
        setCategoryModalDeleteData(category);
    }

    const handleCategoryDeleteModalHide = () => {
        setShowCategoryDeleteModal(false);
    };

    const handleProjectCategoriesDeleted = (category) => {
        setProjectCategoriesFetch(category);
    }

    return (
        <div>
            <div className="text-end">
                <button
                    onClick={() => {
                        handleModalCategoryAdd(null);
                    }}
                    className="btn btn-blue m-3 me-0"
                >
                    Dodaj kategoriju
                </button>
            </div>
            <ul className={classes.listMain}>
                {projectCategories.map((option, i) => {
                    return (
                        <li key={i}>
                            <div className={classes.insideLi}>
                                <div>{option.name}</div>
                                <div>
                                    <button
                                        onClick={() => {
                                            handleModalCategoryEdit(option);
                                        }}
                                        className={classes.linkEdit}
                                    >
                                        Izmjeni
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleModalCategoryAdd(option);
                                        }}
                                        className={classes.linkAdd}
                                    >
                                        Dodaj child
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleModalCategoryDelete(option);
                                        }}
                                        className={classes.linkDelete}
                                    >
                                        Izbriši
                                    </button>
                                </div>
                            </div>
                            {option.childs && option.childs.length > 0 && (
                                <ul>
                                    {option.childs.map((child1, i1) => {
                                        return (
                                            <li key={i1}>
                                                <div className={classes.insideLi}>
                                                    <div>{child1.name}</div>
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                handleModalCategoryEdit(child1);
                                                            }}
                                                            className={classes.linkEdit}
                                                        >
                                                            Izmjeni
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                handleModalCategoryAdd(child1);
                                                            }}
                                                            className={classes.linkAdd}
                                                        >
                                                            Dodaj child
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                handleModalCategoryDelete(child1);
                                                            }}
                                                            className={classes.linkDelete}
                                                        >
                                                            Izbriši
                                                        </button>
                                                    </div>
                                                </div>
                                                {child1.childs && child1.childs.length > 0 && (
                                                    <ul>
                                                        {child1.childs.map((child2, i2) => {
                                                            return (
                                                                <li key={i2}>
                                                                    <div className={classes.insideLi}>
                                                                        <div>{child2.name}</div>
                                                                        <div>
                                                                            <button
                                                                                onClick={() => {
                                                                                    handleModalCategoryEdit(child2);
                                                                                }}
                                                                                className={classes.linkEdit}
                                                                            >
                                                                                Izmjeni
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    handleModalCategoryDelete(child2);
                                                                                }}
                                                                                className={classes.linkDelete}
                                                                            >
                                                                                Izbriši
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
            <div className="text-end">
                <button
                    onClick={() => {
                        handleModalCategoryAdd(null);
                    }}
                    className="btn btn-blue m-3 me-0"
                >
                    Dodaj kategoriju
                </button>
            </div>

            <CategoryEditModal
                show={showCategoryEditModal}
                item={categoryModalEditData}
                hide={handleCategoryEditModalHide}
                projectID={projectID}
                clientID={clientID}
                updateValue={handleProjectCategoriesUpdate}
            />

            <CategoryAddModal
                show={showCategoryAddModal}
                item={categoryModalAddData}
                hide={handleCategoryAddModalHide}
                projectID={projectID}
                clientID={clientID}
                updateValue={handleProjectCategoriesAdded}
            />

            <CategoryDeleteModal
                show={showCategoryDeleteModal}
                item={categoryModalDeleteData}
                hide={handleCategoryDeleteModalHide}
                projectID={projectID}
                clientID={clientID}
                updateValue={handleProjectCategoriesDeleted}
            />

        </div>
    );

};

export default CategoryList;