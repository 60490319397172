import React from 'react';
import { useRecoilValue } from 'recoil';

import {
    projectCurrentYearState,
    projectYearTotalsState,
    projectColumnsState
} from '../../../store';

import { tablePrice } from '../../../helpers/project';

const ProjectTableIncome = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const columns = useRecoilValue(projectColumnsState);
    const currentYear = useRecoilValue(projectCurrentYearState);
    const yearTotals = useRecoilValue(projectYearTotalsState({ projectID, clientID, currentYear }));

    const getColumnsTotalSum = (month) => {

        let categorySum = 0;

        yearTotals.map(total => {
            if (parseInt(total.month_number) === month) {
                categorySum += parseFloat(total.total);
            }
            return null;
        });

        return categorySum;

    };

    let totalQ = 0.0;
    let totalY = 0.0;

    return (
        <React.Fragment>
            <tr>
                <td>TOTAL</td>
                {Object.keys(columns).map((key, j) => {
                    if (j === 0) { return null; }

                    let tdClass = null;

                    let columnSum = getColumnsTotalSum(columns[key].month);

                    totalQ += columnSum;
                    totalY += columnSum;

                    // Show quartall prices
                    if (j === 4 || j === 8 || j === 12 || j === 16) {
                        tdClass = 'quartal';
                        columnSum = totalQ;
                        totalQ = 0.0;
                    }
                    if (j === 17) {
                        tdClass = 'total';
                        columnSum = totalY;
                        totalQ = 0.0;
                        totalY = 0.0;
                    }

                    return <td key={j} className={tdClass}>{tablePrice(columnSum)}</td>
                })}
            </tr>
        </React.Fragment>
    );
}

export default ProjectTableIncome;