import React, { useEffect } from 'react';
import { useRecoilState } from "recoil";

import { successState } from "../../store";
import classes from './Success.module.css';

const Success = () => {

    const [success, setSuccess] = useRecoilState(successState);

    useEffect(() => {
        setTimeout(() => {
            setSuccess(null);
        }, 5000);
    }, [setSuccess]);

    if(success){
        return (
            <div className={classes.success}>
                <p>{success}</p>
            </div>
        );
    }
    
    return null;
    
};

export default Success;