import React, { useEffect } from "react";
import { useNavigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import { currentUserPermissionsState } from "../../../store";

export const ClientsIndex = () => {

    const userPermissions = useRecoilValue(currentUserPermissionsState);
    const navigate = useNavigate();

    // Check user permission
    useEffect(() => {
        if (!userPermissions.includes('manage_clients')) { navigate('/'); }
    }, [userPermissions, navigate]);

    return (
        <>
            <Outlet />
        </>
    );
};