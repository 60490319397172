import React, { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { validateEmptyInput } from '../../../helpers/inputValidate';
import { tokenState, successState, incomeExpanseState } from "../../../store";
import { postAddCategoryData } from "../../../services";

import Modal from 'react-bootstrap/Modal';
import FormElement from '../../elements/FormElement';
import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';

import classes from './CategoryModal.module.css';

const CategoryAddModal = (props) => {

    const projectID = props.projectID;
    const clientID = props.clientID;

    const itemEditData = props.item ? props.item : null;
    const token = useRecoilValue(tokenState);
    const incomeExpanse = useRecoilValue(incomeExpanseState);
    const setSuccess = useSetRecoilState(successState);

    /* Form add new user part with validation */
    let globalError = false;
    const [globalErrorMessage, setGlobalErrorMessage] = useState(false);

    const nameInputRef = useRef();
    const orderingInputRef = useRef();
    const incomeExpanseInputRef = useRef();
    const parentInputRef = useRef();

    const [errorName, setErrorName] = useState(false);
    const errorNameMessage = 'Upišite ispravni naziv osnovne kategorije.';

    const [errorOrdering, setErrorOrdering] = useState(false);
    const errorOrderingMessage = 'Upišite ispravni redoslijed kategorije.';

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredOrdering = orderingInputRef.current.value;
        const enteredParentID = parentInputRef.current.value;
        const enteredIncomeExpanse = incomeExpanseInputRef.current.value;

        // Validate data
        if (!validateEmptyInput(enteredName)) {
            setErrorName(true);
            globalError = true;
        }
        else {
            setErrorName(false);
        }
        if (!validateEmptyInput(enteredOrdering)) {
            setErrorOrdering(true);
            globalError = true;
        }
        else {
            setErrorOrdering(false);
        }

        // Log user if everything ok
        if (
            !globalError
        ) {

            const response = await postAddCategoryData({ enteredName, enteredOrdering, enteredParentID, enteredIncomeExpanse }, projectID, clientID, token);

            if (!response.success) {
                let errorMessage = '';
                Object.keys(response.data).forEach(key => {
                    errorMessage += '<div>' + response.data[key] + '</div>';
                });
                setGlobalErrorMessage(errorMessage);
            }
            else {
                setSuccess(response.message);
                props.updateValue({ item: response.item, type: 'ADD' });
                props.hide();
            }

        }

    }

    return (
        <Modal id='modal-edit' show={props.show} onHide={props.hide} size="lg" className={classes.edit}>
            <Modal.Header closeButton>
                <Modal.Title>Dodajte kategoriju</Modal.Title>
            </Modal.Header>
            <FormElement
                onSubmitHandler={handleFormSubmit} title=""
                classOver={classes.formOver}
                status={globalErrorMessage && <div className="error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
            >
                <Modal.Body>
                    <Input
                        ref={nameInputRef}
                        name="name"
                        type="text"
                        label="Kategorija"
                        autoComplete="off"
                        error={errorName ? errorNameMessage : null}
                    />
                    <Input
                        ref={orderingInputRef}
                        name="ordering"
                        type="number"
                        label="Redoslijed"
                        autoComplete="off"
                        error={errorOrdering ? errorOrderingMessage : null}
                        defaultValue={1}
                        minLength="1"
                    />
                    {itemEditData && (
                        <Input
                            ref={incomeExpanseInputRef}
                            name="income_expanse"
                            type="hidden"
                            label=""
                            defaultValue={itemEditData.income_expanse}
                            autoComplete="off"
                        />
                    )}
                    {!itemEditData && (
                        <Select
                            ref={incomeExpanseInputRef}
                            name="income_expanse"
                            label="Kategorija pripada priljevima ili odljevima?"
                            autoComplete="off"
                            options={incomeExpanse}
                            error={null}
                        />
                    )}
                    {itemEditData && (
                        <Input
                            ref={parentInputRef}
                            name="parent"
                            type="hidden"
                            label={`Kategorija pripada kategoriji: ${itemEditData.name}`}
                            defaultValue={itemEditData ? itemEditData.id : ''}
                            autoComplete="off"
                        />
                    )}
                    {!itemEditData && (
                        <Input
                            ref={parentInputRef}
                            name="parent"
                            type="hidden"
                            label=""
                            defaultValue={null}
                            autoComplete="off"
                        />
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide} type="button" styles="btn btn-blue">Zatvori</Button>
                    <Button variant="danger" type="submit" styles="btn btn-light-blue ms-3">Dodaj kategoriju</Button>
                </Modal.Footer>
            </FormElement>
        </Modal>
    );
};

export default CategoryAddModal;